/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';

import { TranslationContextProvider } from './TranslationContext';

import { AppState } from '../../../reducers/types';
import { Language } from '../../../constants/preferences';
import { KeyedObject } from '../../../constants/misc';
import { getTranslation } from '../../../utils/translation';
import { setLanguage } from '../../../actions/preferences';

import en from '../../../locales/en/translation.json';
import pt from '../../../locales/pt/translation.json';
import es from '../../../locales/es/translation.json';

/**
 * @typedef {Object} StateProps
 * @property {Language} language
 */
interface StateProps {
    language: Language;
}

/**
 * @typedef {Object} DispatchProps
 */
interface DispatchProps {
    updateLanguage: Function;
}

/**
 * @typedef {Object} OwnProps
 * @extends {StateProps}
 * @property {any} children
 * @property {KeyedObject} translations
 */
interface OwnProps extends StateProps{
    children: any;
    translations: KeyedObject;
}

/**
 * @typedef {Object} Props
 */
type Props = DispatchProps & OwnProps & StateProps;


/**
 * @typedef {Object} OwnState
 */
interface OwnState {}

/**
 * @typedef {Object} State
 */
type State = OwnState;

/**
 * provides translation method access to its consumers
 * @extends {Component<Props, State>}
 */
export class TranslationController extends Component<Props, State> {
    static defaultProps = {
        translations: { en, pt, es },
    };

    /**
     * translate to string in language
     * @param {string} literal
     * @param {KeyedObject} [params]
     */
    t = (literal: string, params?: KeyedObject): string => {
        const { translations, language } = this.props;
        return getTranslation(translations, language, literal, params);
    };

    /**
     * set the language on website
     * @param {Language} l
     */
    setLang = (l: Language) => {
        const { updateLanguage } = this.props;

        updateLanguage(l);
    }

    render() {
        const {
            children,
            language,
        } = this.props;

        return (
            <TranslationContextProvider
                value={{
                    language: language,
                    t: this.t,
                    setLang: this.setLang,
                }}
            >
                {children}
            </TranslationContextProvider>
        )
    }
}

/**
 * mapStateToProps
 * @param {AppState} state
 * @returns {StateProps}
 */
const mapStateToProps = (state: AppState): StateProps => {
    return {
        language: state.preferences.language,
    };
};

/**
 * mapDispatchToProps
 * @returns {DispatchProps}
 */
export const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => ({
    updateLanguage: (language: Language) => dispatch(setLanguage(language))
});

export const ConnectedTranslationController = connect(mapStateToProps, mapDispatchToProps)(TranslationController);
