/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { REHYDRATE } from 'redux-persist';

import { PreInspectionState } from './types';
import { PreInspectionAction } from '../actions/preInspections_types';

/**
 * @typedef {PreInspectionState} preInspectionInitialState
 * @property {PreInspectionGlassImages} glass
 */
export const preInspectionInitialState: PreInspectionState = {
    glass: {
        frontLeftWindshield: null,
        frontRightWindshield: null,
        frontWindshield: null,
        licensePlateWindshield: null,
    },
    complete: {
        frontBody: null,
        rightBody:  null,
        leftBody: null,
        backBody: null,
        ceiling: null,
        frontWindshield: null,
        frontRightWindshield: null,
        frontLeftWindshield: null,
        inside: null,
        identification: null,
        document: null,
        inspection: null,
        additionalPhotos: [],
    }
}

/**
 * preInspection reducer
 * @param {PreInspectionState} state
 * @param {any} action
 * @returns {PreInspectionState}
 */
export default function (state = preInspectionInitialState, action: any): PreInspectionState {
    switch (action.type) {
        case REHYDRATE:
            return {
                ...state,
                ...action.payload,
            };
        case PreInspectionAction.SET_GLASS_IMAGES:
            return {
                ...state,
                glass: {
                    ...state.glass,
                    ...action.payload,
                }
            }
        case PreInspectionAction.SET_COMPLETE_IMAGES:
            return {
                ...state,
                complete: {
                    ...state.complete,
                    ...action.payload,
                }
            }
        default: return state;
    }
}
