/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { MatchParams } from '../../../constants/misc';
import { WELCOME_GLASS_ROUTE } from '../../../constants/routes';
import BeforeProceedingMessage from '../../elements/BeforeProceedingMessage';

type OwnProps = RouteComponentProps<MatchParams>

class InformationGlassScreen extends Component<OwnProps> {
    onAdvancedClick = () => {
        const { history } = this.props;
        history.push(WELCOME_GLASS_ROUTE);
    };

    render() {
        return (
            <BeforeProceedingMessage onConfirm={this.onAdvancedClick} />
        );
    }
}

export default InformationGlassScreen;
