/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { ICON, SvgIcon } from '../elements/SvgIcon';

import logo from '../../assets/images/logo_fluxe_vertical.svg';
import smartphone from '../../assets/images/smartphone.svg';

interface OwnProps extends TranslationContext {}

class TokenExpiredScreen extends Component<OwnProps> {
    render() {
        const { t } = this.props;

        return (
            <div className="app-screen">
                <div className="app-screen__container">
                    <div className="app-screen__container__welcome-page">
                        <img src={logo} alt="Fluxe Logo" />
                        <div className="centered">
                            <div className="icon-container">
                                <img src={smartphone} alt="smartphone" />
                                <SvgIcon icon={ICON.WRONG_CROSS} />
                            </div>
                            <p className="big-text">{t('expired.title')}</p>
                        </div>
                        <div className="bottom">
                            <SvgIcon icon={ICON.SUPPORT} />
                            <p>{t('expired.forMoreInformation')}</p>
                            <p>{t('expired.contactServiceInfo')}</p>
                            <h2>{t('expired.contactNumber')}</h2>
                            <p className="regular">{t('expired.helpText')}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslationContext(TokenExpiredScreen);
