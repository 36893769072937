/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import logo from '../../assets/images/logo_fluxe_vertical.svg';
import smartphoneImg from '../../assets/images/smartphone.svg';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';

interface OwnProps extends TranslationContext {}

class DesktopScreen extends Component<OwnProps> {
    render() {
        const { t } = this.props;
        return (
            <div className="desktop-screen" data-testid="desktop-screen-test">
                <div className="desktop-screen__container">
                    <img className="logo" src={logo} alt="Fluxe logo" width="200" />
                    <img src={smartphoneImg} alt="smartphone" />
                    <h3>{t('desktop.mainMessage')}</h3>
                    <p>{t('desktop.helpMessage')}</p>
                </div>
            </div>
        );
    }
}

export default withTranslationContext(DesktopScreen);
