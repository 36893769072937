/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

/**
 * @typedef {Object} VideoData
 * @property {string} token
 * @property {number} preInspectionId
 * @property {string} preInspectionUid
 */
export interface VideoData {
    token: string;
    preInspectionId: number;
    preInspectionUid: string;
}

/**
 * OrientationType
 * @enum {string}
 */
export enum OrientationType {
    LANDSCAPE = 'LANDSCAPE',
    PORTRAIT = 'PORTRAIT'
}
