/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { FilesUpload } from '../constants/misc';

export enum FilesAction {
    SET_FILES = 'SET_FILES',
    RESET_FILES = 'RESET_FILES',
    SET_OPTIONAL_FILES = 'SET_OPTIONAL_FILES',
}

export interface SetFilesAction {
    type: typeof FilesAction.SET_FILES;
    payload: FilesUpload;
}

export interface SetOptionalFilesAction {
    type: typeof FilesAction.SET_OPTIONAL_FILES;
    payload: File[];
}

export interface ResetFiles {
    type: typeof FilesAction.RESET_FILES;
}

export type FilesActionType = SetFilesAction | SetOptionalFilesAction | ResetFiles;
