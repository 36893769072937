/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */


import React, { createContext, ComponentType, FC } from 'react';

import { ConnectedTranslationController } from './TranslationController';

import { Language } from '../../../constants/preferences';

/**
 * @typedef {Object} TranslationContext
 * @property {Language} language
 * @property {function} t
 * @property {function} setLang
 */
export interface TranslationContext {
    language: Language;
    t(literal: string, params?: object): string;
    setLang: Function;
}

// Create the context
export const translationContextDefaultValue: TranslationContext = {
    language: Language.PT,
    t: () => '',
    setLang: () => {}
};

const TranslationContext = createContext<TranslationContext | null>(translationContextDefaultValue);

// Export Provider and Consumer

export const TranslationContextProvider = TranslationContext.Provider;
export const TranslationContextConsumer = TranslationContext.Consumer;

/**
 * provides a context HOC that components can use to get translations
 * @param Component
 * @returns {React.FC}
 */
export const withTranslationContext = <P extends object>(
    Component: ComponentType<P>,
): FC<Omit<P, keyof TranslationContext>> => props => (
    <ConnectedTranslationController>
        <TranslationContextConsumer>
            {ctx => <Component {...props as P} {...ctx} />}
        </TranslationContextConsumer>
    </ConnectedTranslationController>
);
