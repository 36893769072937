/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';
import { KeyedObject } from '../../../constants/misc';
import {
    ApiFile, BrokenGlassControl, BrokenGlassControlPicturesType, CheckInOutImages,
} from '../../../constants/types';

import { ConnectedGlassProviderController } from './GlassProviderController';
 
export interface GlassProviderContext {
    deleteImage(brokenGlassControl: BrokenGlassControl, fileId: number, type: string): Promise<KeyedObject | null>;
    getBrokenGlassDocument(brokenGlassId: number, fileId: number | null): Promise<ApiFile | null>;
    uploadBrokenGlassImages(brokenGlassControl: BrokenGlassControl, file: File, type: string): Promise<KeyedObject | CheckInOutImages>;
    getBrokenGlassControl(brokenGlassId: number): Promise<BrokenGlassControl | null>;
    getBrokenGlassImages(brokenGlassId: number): Promise<CheckInOutImages | null>;
    changeBrokenGlassType(brokenGlassId: number, newType: BrokenGlassControlPicturesType): Promise<KeyedObject | null>;
    checkInBrokenGlass(brokenGlassId: number): Promise<boolean>;
    checkOutBrokenGlass(brokenGlassId: number): Promise<boolean>;
    finishCheckBrokenGlass(brokenGlassControl: BrokenGlassControl): Promise<boolean>;
}

// Create the context
 
export const glassProviderContextDefaultValue = {
    deleteImage: async (): Promise<KeyedObject | null> => null,
    getBrokenGlassDocument: async (): Promise<ApiFile | null> => null,
    uploadBrokenGlassImages: async (): Promise<KeyedObject | CheckInOutImages> => ({}),
    getBrokenGlassControl: async (): Promise<BrokenGlassControl | null> => null,
    getBrokenGlassImages: async (): Promise<CheckInOutImages | null> => null,
    changeBrokenGlassType: async (): Promise<KeyedObject | null> => null,
    checkInBrokenGlass: async (): Promise<boolean> => false,
    checkOutBrokenGlass: async (): Promise<boolean> => false,
    finishCheckBrokenGlass: async (): Promise<boolean> => false,
};
 
const GlassProviderContext = createContext<GlassProviderContext | null>(glassProviderContextDefaultValue);
 
// Export Provider and Consumer
export const GlassProviderContextProvider = GlassProviderContext.Provider;
export const GlassProviderContextConsumer = GlassProviderContext.Consumer;

export const withGlassProviderContext = <P extends object>(
    Component: ComponentType<P>,
): FC<Omit<P, keyof GlassProviderContext>> => props => (
    <ConnectedGlassProviderController>
        <GlassProviderContextConsumer>
            {ctx => <Component {...props as P} {...ctx} />}
        </GlassProviderContextConsumer>
    </ConnectedGlassProviderController>
    );
