/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { RouteComponentProps } from 'react-router-dom';
import { get } from 'lodash';
import ReactSwitch from 'react-switch';
import logo from '../../../assets/images/logo_horizontal.svg';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { ICON, SvgIcon } from '../../elements/SvgIcon';
import FormTextField from '../../elements/FormTextField';
import FormTextAreaField from '../../elements/FormTextAreaField';
import Button from '../../elements/Button';
import { FilesUpload, MatchParams } from '../../../constants/misc';
import { FINAL_ERROR_ROUTE, FINAL_SUCCESS_ROUTE, NOT_FOUND_ROUTE, PICTURES_STEP_ROUTE } from '../../../constants/routes';
import { getFormErrors, IFormError, VALIDATIONS } from '../../../utils/validation';
import { AppState } from '../../../reducers/types';
import { preInspectionsCompleteFinalizeURL } from '../../../services/pre_inspections';
import Loader from '../../elements/Loader';
import MessageBox from '../../elements/MessageBox';

interface OwnProps extends RouteComponentProps<MatchParams>, TranslationContext {}

interface StateProps {
    files: FilesUpload;
    optionalFiles: File[];
    userToken: string;
}

type Props = OwnProps & StateProps;

interface OwnState {
    extrasDescription: string;
    hasExtras: boolean;
    observations: string;
    desiredInsuredSum: number | null;
    formErrors: any;
    isFetching: boolean;
}

const initialState: OwnState = {
    extrasDescription: '',
    hasExtras: true,
    observations: '',
    desiredInsuredSum: null,
    formErrors: null,
    isFetching: false,
};

class InformationScreen extends Component<Props, OwnState> {
    state = initialState;

    componentDidMount(): void {
        const { userToken, history } = this.props;

        if (!userToken || userToken === '') {
            history.push(NOT_FOUND_ROUTE);
        }
    }

    onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            [e.currentTarget.name]: e.currentTarget.value,
        });
    };

    onBackClick = () => {
        const { history } = this.props;
        history.push(PICTURES_STEP_ROUTE);
    };

    onToggleChange = () => {
        const { hasExtras } = this.state;
        this.setState({
            hasExtras: !hasExtras,
        })
    };

    onAcceptClick = () => {
        if (this.validateFields()) {
            const { hasExtras, extrasDescription, observations, desiredInsuredSum } = this.state;

            const fieldValues = {
                extras: hasExtras,
                extrasDescription: String(extrasDescription).trim(),
                desiredInsuredSum: String(desiredInsuredSum).trim(),
                observations: String(observations).trim(),
            };

            this.preInspectionFinalizeRequest(fieldValues);
        }
    };

    validateFields = () => {
        let errors: IFormError | null = getFormErrors(this.state, VALIDATIONS.PRE_INSPECTION_FINALIZE);

        if (errors && Object.keys(errors).length === 0) errors = null;

        this.setState({ formErrors: errors ? { fields: errors } : errors });
        return errors === null;
    };

    preInspectionFinalizeRequest = async (fields: any) => {
        const { history, userToken } = this.props;
        const { isFetching } = this.state;

        if (isFetching) return;

        this.setState({ isFetching: true });

        await axios.post(preInspectionsCompleteFinalizeURL(userToken), fields)
            .then(() => {
                history.push(FINAL_SUCCESS_ROUTE);
            })
            .catch(() => {
                history.push(FINAL_ERROR_ROUTE);
            })
    };

    render() {
        const { t } = this.props;
        const { extrasDescription, hasExtras, observations, desiredInsuredSum, formErrors, isFetching } = this.state;

        return (
            <div className="step-screen">
                {isFetching && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                <div className="step-screen__top-bar">
                    <img src={logo} alt="Fluxe Logo" />
                    <p className="title">{t('global.fluxeName')}</p>
                </div>
                <div className="step-screen__content information-step">
                    <div className="step-screen__content__title-container">
                        <SvgIcon icon={ICON.STEP_2} />
                        <div>
                            <h2 className="title">{t('information.title')}</h2>
                            <p className="subtitle">{t('information.subtitle')}</p>
                        </div>
                    </div>
                    <div className="step-screen__content__middle information-step">
                        <div className="text-toggle-container">
                            <div>
                                <h2 className="title">{t('information.extras')}</h2>
                                <p>{t('information.vehicleHasExtras')}</p>
                            </div>
                            <div className="toggle-container">
                                <p>{t('global.no')}</p>
                                <ReactSwitch
                                    className="react-switch"
                                    onColor="#A81EAD"
                                    offColor="#A81EAD"
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    handleDiameter={27}
                                    height={31}
                                    width={51}
                                    checked={hasExtras}
                                    onChange={this.onToggleChange}
                                />
                                <p>{t('global.yes')}</p>
                            </div>
                        </div>
                        {hasExtras && (
                            <FormTextField
                                name="extrasDescription"
                                value={extrasDescription}
                                onChange={this.onInputChange}
                                placeholder={t('information.extrasPlaceholder')}
                                errors={get(formErrors, 'fields.extrasDescription', null)}
                            />
                        )}
                        <p className="field-description">{t('information.capitalInsurance')}</p>
                        <FormTextField
                            name="desiredInsuredSum"
                            value={desiredInsuredSum}
                            onChange={this.onInputChange}
                            errors={get(formErrors, 'fields.desiredInsuredSum', null)}
                        />
                        <div className="observations-container">
                            <h2 className="title">{t('information.observations')}</h2>
                            <p>{t('information.observationsHelp')}</p>
                            <FormTextAreaField
                                name="observations"
                                value={observations}
                                onChange={this.onInputChange}
                                containerStyles="text-area-container"
                                fieldStyles="text-area"
                                errors={get(formErrors, 'fields.observations', null)}
                            />
                            <div className="buttons-container">
                                <Button
                                    text={t('global.buttons.back')}
                                    styles="btn--purple btn--full-width"
                                    icon={ICON.CHEVRON_LEFT}
                                    iconPosition="left"
                                    callback={this.onBackClick}
                                />
                                <Button
                                    text={t('global.buttons.advance')}
                                    styles="btn--green btn--full-width"
                                    icon={ICON.CHEVRON_RIGHT}
                                    iconPosition="right"
                                    callback={this.onAcceptClick}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <MessageBox message={t('picturesUpload.uploadingMessage')} showModal={isFetching} />
            </div>
        )
    }
}

function mapStateToProps({ files, general }: AppState): StateProps {
    const { files: filesUpload, optionalFiles } = files;
    const { userToken } = general;

    return {
        files: filesUpload,
        optionalFiles,
        userToken: userToken || '',
    }
}

export default connect(mapStateToProps)(withTranslationContext(InformationScreen));
