export type IErrorCode = {
    [key: number]: string;
}

export const ERROR_CODE: IErrorCode = {
    0: 'general',
    1: 'multiple',
    29: 'errorInvalidPhoto',
};

export const getErrorString = (errorCode: number): string => {
    return ERROR_CODE[errorCode];
};
