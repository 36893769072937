/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { SelectOption } from '../../constants/misc';
import { languageNameToEnum } from '../../constants/preferences';

/**
 * @typedef {Object} OwnProps
 * @extends {TranslationContext}
 */
interface OwnProps extends TranslationContext{}

/**
 * @typedef {Object} OwnState
 * @property {SelectOption} [langOptions]
 * @property {string} selectedLang
 */
interface OwnState {
    langOptions: Array<SelectOption>
    selectedLang: string
}

const initialState: OwnState = {
    langOptions: [],
    selectedLang: ''
}

class LangSelector extends Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount() {
        const { language } = this.props;

        const langOptions = [
            {
                value: 'pt',
                label: 'pt',
            },
            {
                value: 'en',
                label: 'en',
            },
            {
                value: 'es',
                label: 'es',
            },
        ];
        
        this.setState({
            selectedLang: language,
            langOptions,
        })
    }

    onChangeLang = (e: React.FormEvent<HTMLSelectElement>) => {
        const { setLang } = this.props;
        const value = e.currentTarget.value;

        this.setState({
            selectedLang: value,
        });

        setLang(languageNameToEnum(value));
    }

    render() {
        const { t } = this.props;
        const {
            selectedLang,
            langOptions,
        } = this.state;

        return (
            <div className="lang-container">
                <div className="label">{t('lang.selectLabel')}</div>
             
                <div className="select">
                    <select name="selectedLang" value={selectedLang} onChange={e => this.onChangeLang(e)}>
                        {Object.keys(langOptions).map(o => {
                            const opt = langOptions[Number(o)];

                            return (
                                <option key={opt.value} value={opt.value}> {t(`lang.${opt.label}`)} </option>
                            )
                        })}
                    </select>
                </div>
            </div>
        );
    }
}

export default withTranslationContext(LangSelector);
