/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { SvgIcon } from './SvgIcon';

interface OwnProps {
    isLink?: boolean,
    type?: 'button' | 'submit',
    text?: string,
    styles?: string,
    callback?: ((event: React.MouseEvent<HTMLButtonElement>) => void),
    icon?: string,
    name?: string,
    iconPosition?: string,
}

class Button extends Component<OwnProps> {
    getClassNames = (): string => {
        const { styles, isLink, icon, text, iconPosition } = this.props;

        let baseStyle: string = 'btn';

        if (styles) {
            baseStyle = `btn ${styles}`
        }

        const linkStyle: string = isLink ? 'btn--link' : '';

        let iconStyle: string = '';
        let iconPosStyle: string = '';
        if (icon) {
            if (text && text.length > 0) {
                iconStyle = 'btn--with-icon';
                iconPosStyle = `btn--with-icon--${iconPosition}`;
            } else {
                iconStyle = 'btn--just-icon';
            }
        }

        return `${baseStyle} ${linkStyle} ${iconStyle} ${iconPosStyle}`;
    };

    render() {
        const { text, callback, type, icon, name } = this.props;

        return (
            <button
                type={type || 'button'}
                className={this.getClassNames()}
                onClick={callback}
                name={name || ''}
            >
                <span>{text}</span>
                {icon && <SvgIcon icon={icon} />}
            </button>
        );
    }
}

export default Button;
