/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import logo from '../../../assets/images/logo_fluxe_vertical.svg';
import { HistoryState, MatchParams } from '../../../constants/misc';
import { ICON, SvgIcon } from '../../elements/SvgIcon';

interface OwnProps extends RouteComponentProps<MatchParams, {}, HistoryState>, TranslationContext {}

interface OwnState {
    callDuration: string | null;
    callWasDeclined: boolean;
    videoError: boolean;
}

const initialState: OwnState = {
    callDuration: null,
    callWasDeclined: false,
    videoError: false,
};

class CallEndScreen extends Component<OwnProps> {
    state = { ...initialState };

    componentDidMount(): void {
        const { history } = this.props;
        const { location } = history;
        const { state } = location;

        this.setState({
            callDuration: state && state.callDuration ? state.callDuration : null,
            callWasDeclined: !!(state && state.callWasDeclined),
            videoError: !!(state && state.videoError),
        });

        history.replace({
            state: {}
        })
    }

    render() {
        const { t } = this.props;
        const { callDuration, callWasDeclined, videoError } = this.state;

        return (
            <div className="video-call">
                <div className="video-call__information-screen">
                    <img src={logo} alt="Fluxe Logo" />
                    {callDuration && (
                        <div className="column-info">
                            <h3>{t('videoCall.callEnded')}</h3>
                            <p>{t('videoCall.duration', { time: callDuration })}</p>
                        </div>
                    )}
                    {callWasDeclined && (
                        <div className="column-info">
                            <h3>{t('videoCall.callWasDeclined')}</h3>
                        </div>
                    )}
                    {videoError && (
                        <div className="column-info">
                            <h3>{t('videoCall.errorOccurred')}</h3>
                            <p>{t('videoCall.needsCameraMicrophonePermissions')}</p>
                            <p>{t('videoCall.errorInformation')}</p>
                        </div>
                    )}
                    <div>
                        <SvgIcon icon={ICON.SUPPORT} />
                        <p>{t('expired.forMoreInformation')}</p>
                        <p>{t('expired.contactServiceInfo')}</p>
                        <h2>{t('expired.contactNumber')}</h2>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslationContext(CallEndScreen);

