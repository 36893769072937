/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';
import { ReactSVG } from 'react-svg';

import svgLoader from '../../assets/images/loader.svg';

function Loader() {
    return (
        <div className="loader" data-testid="loader-wrapper">
            <ReactSVG
                wrapper="span"
                src={svgLoader}
            />
        </div>
    );
}

export default Loader;
