/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { GeneralContextProvider } from './GeneralContext';
import { AppState } from '../../../reducers/types';
import { setUserTokenActionCreator } from '../../../actions/general';

interface StateProps {
    userToken: string;
}

interface DispatchProps {
    dispatchSetUserToken: (token: string) => void;
}

interface OwnProps extends StateProps {
    children: React.ReactNode;
}

type Props = StateProps & OwnProps & DispatchProps;

export class GeneralController extends Component<Props> {
    setUserToken = (token: string): void => {
        const { dispatchSetUserToken } = this.props;
        dispatchSetUserToken(token);
    }

    render(): React.ReactNode {
        const {
            userToken,
            children,
        } = this.props;

        return (
            <GeneralContextProvider
                value={{
                    userToken,
                    setUserToken: this.setUserToken,
                }}
            >
                {children}
            </GeneralContextProvider>
        );
    }
}

/**
 * mapStateToProps
 * @param {AppState} state
 * @returns {StateProps}
 */
const mapStateToProps = (state: AppState): StateProps => {
    return {
        userToken: state.general.userToken,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>): DispatchProps => {
    return {
        dispatchSetUserToken: (token: string) => dispatch(setUserTokenActionCreator(token)),
    };
};

export const ConnectedGeneralController = connect(mapStateToProps, mapDispatchToProps)(GeneralController);
