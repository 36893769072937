/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import logo from '../../../assets/images/logo_fluxe_horizontal.svg';
import assistLogo from '../../../assets/images/fluxe_assist_logo.svg';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { ICON, SvgIcon } from '../../elements/SvgIcon';
import { MatchParams } from '../../../constants/misc';
import { NOT_FOUND_ROUTE, PICTURES_STEP_ROUTE } from '../../../constants/routes';
import { AppState } from '../../../reducers/types';

interface OwnProps extends RouteComponentProps<MatchParams>, TranslationContext {}

interface StateProps {
    userToken: string;
}

type Props = OwnProps & StateProps;

class CompleteWelcomeScreen extends Component<Props> {
    componentDidMount(): void {
        const { userToken, history } = this.props;

        if (!userToken || userToken === '') {
            history.push(NOT_FOUND_ROUTE);
        }
    }

    onStepOneClick = () => {
        const { history } = this.props;
        history.push(PICTURES_STEP_ROUTE);
    };

    render() {
        const { t } = this.props;

        return (
            <div className="app-screen">
                <div className="app-screen__container">
                    <div className="app-screen__container__welcome-page">
                        <img src={logo} alt="Fluxe Logo" />
                        <div className="title-container">
                            <h4>{t('welcome.welcomeMsg')}</h4>
                            <img src={assistLogo} alt="Fluxe Assist Logo" />
                        </div>
                        <div className="centered">
                            <p>{t('welcome.helpMsg')}</p>
                            <p>{t('welcome.helpMsg2')}</p>
                            <div className="step green" onClick={this.onStepOneClick}>
                                <SvgIcon icon={ICON.STEP_1} />
                                <div>
                                    <p className="title">{t('welcome.step1')}</p>
                                    <p className="subtitle">{t('welcome.step1HelpText')}</p>
                                </div>
                            </div>
                            <div className="step purple">
                                <SvgIcon icon={ICON.STEP_2} />
                                <div>
                                    <p className="title">{t('welcome.step2')}</p>
                                    <p className="subtitle">{t('welcome.step2HelpText')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="bottom">
                            <SvgIcon icon={ICON.SUPPORT} />
                            <p>{t('welcome.forMoreInformation')}</p>
                            <p>{t('welcome.contactServiceInfo')}</p>
                            <h2>{t('welcome.contactNumber')}</h2>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps({ general }: AppState): StateProps {
    const { userToken } = general;

    return {
        userToken: userToken || '',
    }
}

export default connect(mapStateToProps)(withTranslationContext(CompleteWelcomeScreen));
