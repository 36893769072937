/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { createStore, compose, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import general from './reducers/general';
import files from './reducers/files';
import preferences from './reducers/preferences';
import preInspection from './reducers/preInspection';

const isDevVersion: boolean = process.env.NODE_ENV === 'development';
const composeEnhancers = composeWithDevTools || compose;
const middlewares: any = [ReduxThunk];
const volatileReducers: any = [
    'files',
];

const config = {
    key: 'root',
    storage,
    debug: isDevVersion,
    blacklist: volatileReducers,
};

const reducers = persistCombineReducers(config, {
    general,
    preferences,
    files,
    preInspection,
});

export const configureStore = () => {
    const store = createStore(
        reducers,
        composeEnhancers(applyMiddleware(...middlewares)),
    );

    const persistor = persistStore(store, null);

    return { persistor, store };
};
