/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';

import { ConnectedGeneralController } from './GeneralController';

export interface GeneralContext {
    userToken: string;
    setUserToken(token: string): void;
}

// Create the context

export const generalContextDefaultValue = {
    userToken: '',
    setUserToken: (): void => {},
};

const GeneralContext = createContext<GeneralContext | null>(generalContextDefaultValue);

// Export Provider and Consumer
export const GeneralContextProvider = GeneralContext.Provider;
export const GeneralContextConsumer = GeneralContext.Consumer;

/**
 * provides a context HOC that components can use to subscribe as consumers
 */
export const withGeneralContext = <P extends object>(
    Component: ComponentType<P>,
): FC<Omit<P, keyof GeneralContext>> => props => (
    <ConnectedGeneralController>
        <GeneralContextConsumer>
            {ctx => <Component {...props as P} {...ctx} />}
        </GeneralContextConsumer>
    </ConnectedGeneralController>
    );
