/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import axios, { AxiosError } from 'axios';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { KeyedObject } from '../../../constants/misc';
import {
    ApiFile, BrokenGlassControl, BrokenGlassControlPicturesType, CheckInOutImages,
} from '../../../constants/types';
import {
    brokenGlassControlDeleteCheckInPhotoURL, brokenGlassControlDocumentsURL,
    brokenGlassControlDocumentURL, brokenGlassControlTypeURL, brokenGlassControlUploadCheckInURL,
    brokenGlassControlURL, brokenGlassControlCheckInURL, brokenGlassControlCheckOutURL,
    brokenGlassControlUploadCheckOutURL, brokenGlassControlDeleteCheckOutPhotoURL,
} from '../../../services/broken_glass_controls';
import { GlassProviderContextProvider } from './GlassProviderContext';

interface OwnProps {
    children: React.ReactNode;
}

type Props = OwnProps;
 
export class GlassProviderController extends Component<Props> {
    deleteImage = async (brokenGlassControl: BrokenGlassControl, fileId: number, type: string): Promise<KeyedObject | null> => {
        const url = brokenGlassControl.checkedIn ? brokenGlassControlDeleteCheckOutPhotoURL : brokenGlassControlDeleteCheckInPhotoURL;

        try {
            await axios.delete(url(brokenGlassControl.id, type, fileId));
            return null;
        } catch (e) {
            return (e as AxiosError)?.response?.data || {};
        }
    }

    uploadBrokenGlassImages = async (brokenGlassControl: BrokenGlassControl, file: File, type: string): Promise<KeyedObject | CheckInOutImages> => {
        const url = brokenGlassControl.checkedIn ? brokenGlassControlUploadCheckOutURL : brokenGlassControlUploadCheckInURL;
        try {
            const { data } = await axios.post(
                url(brokenGlassControl.id, type),
                file,
                {
                    headers: {
                        'content-type': 'application/octet-stream',
                    },
                },
            );
            return data;
        } catch (e) {
            const error = (e as AxiosError)?.response?.data || {};
            return { ...error, isError: true };
        }
    }

    getBrokenGlassControl = async (brokenGlassId: number | string): Promise<BrokenGlassControl | null> => {
        if (!brokenGlassId || brokenGlassId < 0) {
            return null;
        }

        try {
            const { data } = await axios.get(brokenGlassControlURL(Number(brokenGlassId)));
            return data;
        } catch {
            return null;
        }
    }

    getBrokenGlassDocument = async (brokenGlassId: number, fileId: number | null): Promise<ApiFile | null> => {
        if (fileId === null) return null;

        try {
            const { data } = await axios.get(brokenGlassControlDocumentURL(brokenGlassId, fileId), { responseType: 'blob' });
            const blob = new Blob([data], { type: data.type });
            return { fileString: URL.createObjectURL(blob), id: fileId };
        } catch {
            return null;
        }
    }

    getBrokenGlassImages = async (brokenGlassId: number): Promise<CheckInOutImages | null> => {
        try {
            const { data } = await axios.get(brokenGlassControlDocumentsURL(brokenGlassId));
            return data;
        } catch {
            return null;
        }
    }

    changeBrokenGlassType = async (brokenGlassId: number, newType: BrokenGlassControlPicturesType): Promise<KeyedObject | null> => {
        try {
            await axios.put(brokenGlassControlTypeURL(brokenGlassId), { controlType: newType });
            return null;
        } catch (e) {
            return (e as AxiosError)?.response?.data || {};
        }
    }

    checkInBrokenGlass = async (brokenGlassId: number): Promise<boolean> => {
        try {
            await axios.get(brokenGlassControlCheckInURL(brokenGlassId));
            return true;
        } catch {
            return false;
        }
    }

    checkOutBrokenGlass = async (brokenGlassId: number): Promise<boolean> => {
        try {
            await axios.get(brokenGlassControlCheckOutURL(brokenGlassId));
            return true;
        } catch {
            return false;
        }
    }

    finishCheckBrokenGlass = async (brokenGlassControl: BrokenGlassControl): Promise<boolean> => {
        if (brokenGlassControl.checkedIn) {
            return this.checkOutBrokenGlass(brokenGlassControl.id);
        }

        return this.checkInBrokenGlass(brokenGlassControl.id);
    }

    render(): React.ReactNode {
        const {
            children,
        } = this.props;

        return (
            <GlassProviderContextProvider
                value={{
                    deleteImage: this.deleteImage,
                    uploadBrokenGlassImages: this.uploadBrokenGlassImages,
                    getBrokenGlassControl: this.getBrokenGlassControl,
                    getBrokenGlassDocument: this.getBrokenGlassDocument,
                    getBrokenGlassImages: this.getBrokenGlassImages,
                    changeBrokenGlassType: this.changeBrokenGlassType,
                    checkInBrokenGlass: this.checkInBrokenGlass,
                    checkOutBrokenGlass: this.checkOutBrokenGlass,
                    finishCheckBrokenGlass: this.finishCheckBrokenGlass,
                }}
            >
                {children}
            </GlassProviderContextProvider>
        );
    }
}
 
export const ConnectedGlassProviderController = connect()(GlassProviderController);
