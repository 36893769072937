/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { REHYDRATE } from 'redux-persist';
import { PreferencesState } from './types';
import {
    PreferencesAction,
} from '../actions/preferences_types';
import {
    Language,
    languageNameToEnum,
} from '../constants/preferences';

/**
 * @typedef {PreferencesState} preferencesInitialState
 */
export const preferencesInitialState: PreferencesState = {
    language: Language.PT,
};

/**
 * user preferences reducer
 * @param {PreferencesState} state
 * @param {any} action
 * @returns {PreferencesState}
 */
export default function (state = preferencesInitialState, action: any): PreferencesState {
    switch (action.type) {
        case REHYDRATE:
            return state;
        case PreferencesAction.SET_LANGUAGE:
            return {
                ...state,
                language: action.payload,
            };
        case PreferencesAction.PREFERENCES_REQUEST:
        case PreferencesAction.PREFERENCES_CHANGE_REQUEST:
        case PreferencesAction.PREFERENCES_SUCCESS:
        case PreferencesAction.PREFERENCES_CHANGE_SUCCESS:
        case PreferencesAction.PREFERENCES_FAILURE:
        case PreferencesAction.PREFERENCES_CHANGE_FAILURE:
            return {
                ...state,
                language: languageNameToEnum(action.payload.language),
            };
        default:
            return state;
    }
}
