/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
    INDEX_ROUTE,
    INFORMATION_STEP_ROUTE,
    NOT_FOUND_ROUTE,
    PICTURES_STEP_ROUTE,
    WELCOME_COMPLETE_ROUTE,
    TOKEN_EXPIRED_ROUTE,
    FINAL_ERROR_ROUTE,
    FINAL_SUCCESS_ROUTE,
    WELCOME_GLASS_ROUTE,
    INFORMATION_GLASS_ROUTE,
    FINAL_GLASS_ERROR_ROUTE,
    FINAL_GLASS_SUCCESS_ROUTE,
    VIDEO_CALL_ROUTE,
    VIDEO_ROUTE,
    VIDEO_CALL_ENDED_ROUTE,
    DISPUTE_ROUTE,
    CHECK_IN_ROUTE,
    CHECK_OUT_ROUTE,
} from '../constants/routes';
import HomeScreen from './screens/HomeScreen';
import DesktopScreen from './screens/DesktopScreen';
import PicturesUploadScreen from './screens/complete/FilesUploadScreen';
import InformationScreen from './screens/complete/InformationScreen';
import TokenExpiredScreen from './screens/TokenExpiredScreen';
import FinalScreen from './screens/complete/FinalScreen';
import NotFound from './screens/NotFoundScreen';
import CompleteWelcomeScreen from './screens/complete/CompleteWelcomeScreen';
import GlassWelcomeScreen from './screens/glass/GlassWelcomeScreen';
import InformationGlassScreen from './screens/glass/InformationGlassScreen';
import GlassFinalScreen from './screens/glass/GlassFinalScreen';
import InformationVideoCall from './screens/videocall/InformationVideoCall';
import VideoCallScreen from './screens/videocall/VideoCallScreen';
import CallEndScreen from './screens/videocall/CallEndScreen';
import LangSelector from './elements/LangSelector';
import DisputeScreen from './screens/glass/DisputeScreen';

import CheckInOutScreen from './screens/brokenGlassControl/CheckInOutScreen';

function Router() {
    return (
        <div className="app-router">
            <LangSelector />
            {/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
                navigator.userAgent
            ) ? (
                <Switch>
                    <Route exact path={NOT_FOUND_ROUTE} component={NotFound} />
                    <Route exact path={`${INDEX_ROUTE}/:token`} component={HomeScreen} />
                    <Route exact path={WELCOME_COMPLETE_ROUTE} component={CompleteWelcomeScreen} />
                    <Route exact path={WELCOME_GLASS_ROUTE} component={GlassWelcomeScreen} />
                    <Route exact path={PICTURES_STEP_ROUTE} component={PicturesUploadScreen} />
                    <Route exact path={INFORMATION_STEP_ROUTE} component={InformationScreen} />
                    <Route exact path={INFORMATION_GLASS_ROUTE} component={InformationGlassScreen} />
                    <Route exact path={TOKEN_EXPIRED_ROUTE} component={TokenExpiredScreen} />
                    <Route exact path={FINAL_ERROR_ROUTE} component={FinalScreen} />
                    <Route exact path={FINAL_SUCCESS_ROUTE} component={FinalScreen} />
                    <Route exact path={FINAL_GLASS_ERROR_ROUTE} component={GlassFinalScreen} />
                    <Route exact path={FINAL_GLASS_SUCCESS_ROUTE} component={GlassFinalScreen} />
                    <Route exact path={`${VIDEO_CALL_ROUTE}/:userId/:token`} component={InformationVideoCall} />
                    <Route exact path={VIDEO_ROUTE} component={VideoCallScreen} />
                    <Route exact path={VIDEO_CALL_ENDED_ROUTE} component={CallEndScreen} />
                    <Route exact path={DISPUTE_ROUTE} component={DisputeScreen} />
                    <Route exact path={CHECK_IN_ROUTE} component={CheckInOutScreen} />
                    <Route exact path={CHECK_OUT_ROUTE} component={CheckInOutScreen} />
                    <Redirect to={NOT_FOUND_ROUTE} />
                </Switch>
            ) : (
                <DesktopScreen />
            )}
        </div>
    );
}

export default Router;
