/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';

export const disputesURL = (userToken: string) => {
    return `${API_URL}/disputes/${userToken}`;
};
