/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import logo from '../../../assets/images/logo_fluxe_vertical.svg';
import { RouteComponentProps } from 'react-router-dom';
import { MatchParams } from '../../../constants/misc';
import { FINAL_ERROR_ROUTE } from '../../../constants/routes';
import assistLogo from '../../../assets/images/fluxe_assist_logo.svg';
import { ICON, SvgIcon } from '../../elements/SvgIcon';

interface OwnProps extends RouteComponentProps<MatchParams>, TranslationContext {}

interface OwnState {
    isError: boolean;
}

const initialState = {
    isError: false,
};

class FinalScreen extends Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount(): void {
        const { match } = this.props;
        const { path } = match;

        let isError: boolean = false;
        if (path === FINAL_ERROR_ROUTE) {
            isError = true;
        }

        this.setState({ isError });
    }

    render() {
        const { t } = this.props;
        const { isError } = this.state;

        return (
            <div className="app-screen">
                <div className="top-bar" />
                <div className="app-screen__container">
                    <div className="app-screen__container__welcome-page">
                        <img src={logo} alt="Fluxe Logo" />
                        <div className="centered">
                            <div className="final-icon-container">
                                <SvgIcon icon={isError ? ICON.STEP_1_ERROR : ICON.STEP_1_SUCCESS} />
                                <SvgIcon icon={isError ? ICON.STEP_2_ERROR : ICON.STEP_2_SUCCESS} />
                            </div>
                        </div>
                        <div className="bottom">
                            <img src={assistLogo} alt="Fluxe Assist Logo" />
                            <p className="bold">{isError ? t('final.errorMsg') : t('final.successMsg')}</p>
                        </div>
                        {isError && (
                            <div className="bottom">
                                <SvgIcon icon={ICON.SUPPORT} />
                                <p>{t('expired.forMoreInformation')}</p>
                                <p>{t('expired.contactServiceInfo')}</p>
                                <h2>{t('expired.contactNumber')}</h2>
                                <p className="regular">{t('expired.helpText')}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslationContext(FinalScreen);
