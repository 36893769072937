/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';
import { ApiFile, ControlGlassImages, ControlGlassImagesKeys, PhotosUploadingState } from '../../../constants/types';
import { parseStringType } from '../../../utils/misc';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import AdditionalPhotos from '../AdditionalPhotos';
import DocumentUpload from '../DocumentUpload';
import { PhotoAngleType } from '../../../constants/misc';
import { ICON } from '../SvgIcon';

interface OwnProps {
    onFileSelected: (file: File, name: keyof ControlGlassImages, additionalPhotoIndex?: number) => void;
    onFileDeleted: (file: ApiFile | null, name: keyof ControlGlassImages) => void;
    controlGlassImages: ControlGlassImages;
    uploadingImages: PhotosUploadingState<ControlGlassImages>;
}

type Props = OwnProps & TranslationContext
  
const WindshieldUploadPhotos: React.FC<Props> = (props: Props) => {
    const {
        controlGlassImages, onFileDeleted, onFileSelected, t, uploadingImages,
    } = props;

    const uploadFile = (file: File, name: keyof ControlGlassImages, additionalPhotoIndex?: number): void => {
        onFileSelected(file, name, additionalPhotoIndex);
    };

    const glassControlPhotoNames: Array<ControlGlassImagesKeys> = [
        ControlGlassImagesKeys.FrontLeftWindshield,
        ControlGlassImagesKeys.FrontWindshield,
        ControlGlassImagesKeys.FrontRightWindshield,
        ControlGlassImagesKeys.Serigraphy,
        ControlGlassImagesKeys.FrontBody,
    ];
    
    return (
        <React.Fragment>
            {
                glassControlPhotoNames.map(key => {
                    let photoAngle = Object.keys(PhotoAngleType).find(c => c.replace(/_/g, '').toLowerCase() === key.toLowerCase());
                    const glassPhoto = controlGlassImages[key];
                    const isFetching = uploadingImages[key] || false;
                    
                    return (
                        <DocumentUpload
                            key={key}
                            isFetching={isFetching}
                            file={glassPhoto?.fileString}
                            onSelected={(file): void => uploadFile(file, key)}
                            onRemove={(): void => onFileDeleted(glassPhoto, key)}
                            titleText={key === ControlGlassImagesKeys.FrontBody ? t('picturesUpload.photosTitle.licensePlateWindshield') : t(`picturesUpload.photosTitle.${key}`)}
                            extraText={key === ControlGlassImagesKeys.FrontBody ? t('picturesUpload.photosSubtitle.licensePlateWindshield') : t(`picturesUpload.photosSubtitle.${key}`)}
                            styles="dark-purple glass-icon position-relative no-top-margin"
                            iconPVC={key === ControlGlassImagesKeys.FrontBody ? ICON.LICENSE_PLATE_WINDSHIELD : parseStringType(key)}
                            photoAngle={photoAngle}
                        />
                    );
                })
            }
            <AdditionalPhotos
                files={controlGlassImages.additionalPhotos}
                maximumFiles={3}
                onFileDeleted={(f): void => onFileDeleted(f, 'additionalPhotos')}
                onFileSelected={(file, index): void => uploadFile(file, 'additionalPhotos', index)}
            />
        </React.Fragment>
    );
};
  
export default withTranslationContext(WindshieldUploadPhotos);
