/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { RouteComponentProps } from 'react-router-dom';
import { setFilesActionCreator, setOptionalFilesActionCreator } from '../../../actions/files';
import logo from '../../../assets/images/logo_horizontal.svg';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { ICON, SvgIcon } from '../../elements/SvgIcon';
import DocumentUpload from '../../elements/DocumentUpload';
import { MatchParams, FilesUpload, PhotoAngleType } from '../../../constants/misc';
import Button from '../../elements/Button';
import { INFORMATION_STEP_ROUTE, NOT_FOUND_ROUTE } from '../../../constants/routes';
import { AppState } from '../../../reducers/types';
import { PreInspectionContext, withPreInspectionContext } from '../../controllers/preInspection/PreInspectionContext';
import {
    preInspectionsCompleteRemoveAdditionalFile,
    preInspectionsCompleteUpload,
} from '../../../services/pre_inspections';
import { parseStringType } from '../../../utils/misc';
import InvalidImageModal from '../../elements/InvalidImageModal';
import MessageBox from '../../elements/MessageBox';

interface OwnProps extends RouteComponentProps<MatchParams>, TranslationContext, PreInspectionContext {}

interface DispatchProps {
    setFiles: Function;
    setOptionalFiles: Function;
}

interface StateProps {
    files: FilesUpload;
    optionalFiles: File[];
    userToken: string;
}

type Props = OwnProps & StateProps & DispatchProps;

interface OwnState {
    hasMandatoryError: boolean;
    hasFileTypeError: boolean;
    hasOptionalFileTypeError: boolean;
    hasServerDownError: boolean;
    showModal: boolean;
    showUploadingMessage: boolean;
    invalidFileUrl: string;
    fetchingImages: any;
}

const initialState: OwnState = {
    hasMandatoryError: false,
    hasFileTypeError: false,
    hasOptionalFileTypeError: false,
    hasServerDownError: false,
    showModal: false,
    showUploadingMessage: false,
    invalidFileUrl: '',
    fetchingImages: {
        frontBody: false,
        leftBody: false,
        rightBody: false,
        frontWindshield: false,
        backBody: false,
        ceiling: false,
        frontLeftWindshield: false,
        frontRightWindshield: false,
        inside: false,
        identification: false,
        document: false,
        inspection: false,
        additionalPhotos0: false,
        additionalPhotos1: false,
        additionalPhotos2: false,
        additionalPhotos3: false,
        additionalPhotos4: false,
        additionalPhotos5: false,
        additionalPhotos6: false,
        additionalPhotos7: false,
        additionalPhotos8: false,
        additionalPhotos9: false,
        additionalPhotos10: false,
        additionalPhotos11: false,
    },
};

class FilesUploadScreen extends Component<Props, OwnState> {
    state = initialState;

    componentDidMount(): void {
        const { userToken, history } = this.props;

        if (!userToken || userToken === '') {
           history.push(NOT_FOUND_ROUTE);
        } 
    }

    onFileSelected = (file: File, type: string, isOptional = false, position?: number) => {
        const { userToken, setCompletePreInspectionPhotos, complete } = this.props;
        const { type: fileType } = file;
        const { fetchingImages } = this.state;
        const { additionalPhotos } = complete;
        let typeDescription = type;

        if (fetchingImages[type]) return;

        if (!fileType.startsWith('image') || String(fileType).includes('svg')) {
            this.setState({ hasOptionalFileTypeError: true });
            return;
        }

        const config = {
            headers: {
                'content-type': 'application/octet-stream',
            },
        };

        const invalidFileUrl = URL.createObjectURL(file);

        if (isOptional) {
            typeDescription = `additionalPhotos${position}`;
        }

        this.setState({
            fetchingImages: {
                ...this.state.fetchingImages,
                [typeDescription]: true,
            },
            hasMandatoryError: false,
            hasServerDownError: false,
            hasFileTypeError: false,
            hasOptionalFileTypeError: false,
        });

        const typeStr = isOptional ? 'ADDITIONAL_PHOTOS' : parseStringType(type);

        axios.post(preInspectionsCompleteUpload(userToken, typeStr), file, config).then(response => {
            this.setState({
                fetchingImages: {
                    ...this.state.fetchingImages,
                    [typeDescription]: false,
                },
                hasFileTypeError: false,
            });

            const { data } = response;
            let imageId = '';

            if (isOptional) {
                if (position !== undefined && position !== null) {
                    if (data && data.additionalPhotos && data.additionalPhotos[position]) {
                        imageId = data.additionalPhotos[position].id;
                    }

                    additionalPhotos[position] = {
                        id: imageId,
                        url: invalidFileUrl,
                    };
                } else {
                    additionalPhotos.push({
                        id: imageId,
                        url: invalidFileUrl,
                    });
                }
                setCompletePreInspectionPhotos('additionalPhotos', additionalPhotos);
            } else {
                setCompletePreInspectionPhotos(type, invalidFileUrl);
            }
        }).catch(error => {
            this.setState({
                fetchingImages: {
                    ...this.state.fetchingImages,
                    [typeDescription]: false,
                },
            });
            if (error && error.response) {
                const { status, data } = error.response;

                if (status === 400 && data && data.errors && data.errors.length > 0) {
                    const { errors } = data;

                    errors.forEach((e: any) => {
                        if (e.errorCode === 29) {
                            this.setState({
                                showModal: true,
                                invalidFileUrl,
                            });
                        }
                    });
                }

                if (status === 502) {
                    this.setState({
                        hasServerDownError: true,
                    });
                }
            }
        });
    };

    onFileRemoved = (type: string, isOptional = false, position?: number) => {
        const {
            complete,
            setCompletePreInspectionPhotos,
            userToken,
        } = this.props;

        const { additionalPhotos } = complete;

        if (isOptional && position !== undefined && position !== null) {
            const image = additionalPhotos[position];

            axios.delete(preInspectionsCompleteRemoveAdditionalFile(userToken, image.id)).then(() => {
                additionalPhotos.splice(position, 1);
                setCompletePreInspectionPhotos('additionalPhotos', additionalPhotos);
            }).catch(() => { });
        } else {
            setCompletePreInspectionPhotos(type, null);
        }
    };

    onAdvancedClick = () => {
        const { history, complete } = this.props;

        const {
            frontBody,
            frontLeftWindshield,
            frontRightWindshield,
            frontWindshield,
            backBody,
            ceiling,
            inside,
            leftBody,
            rightBody,
        } = complete;

        let canSend = true;

        if (!frontBody || !frontLeftWindshield || !frontRightWindshield || !frontWindshield || !backBody || !ceiling || !inside || !leftBody || !rightBody) {
            canSend = false;
        }

        if (canSend) history.push(INFORMATION_STEP_ROUTE);
        else this.setState({ hasMandatoryError: true });
    };

    isFetchingImage = () => {
        const { fetchingImages } = this.state;

        let isFetching = false;

        Object.keys(fetchingImages).forEach(key => {
            if (fetchingImages[key]) isFetching = true;
        });

        return isFetching;
    }

    render() {
        const { t, complete } = this.props;
        const {
            hasMandatoryError,
            hasFileTypeError,
            hasOptionalFileTypeError,
            hasServerDownError,
            invalidFileUrl,
            showModal,
            fetchingImages,
            showUploadingMessage,
        } = this.state;

        const count: number = complete.additionalPhotos.length;

        return (
            <div className="step-screen">
                <div className="step-screen__top-bar">
                    <img src={logo} alt="Fluxe Logo" />
                    <p className="title">{t('global.fluxeName')}</p>
                </div>
                <div className="step-screen__content">
                    <div className="step-screen__content__title-container">
                        <SvgIcon icon={ICON.STEP_1} />
                        <div>
                            <h2 className="title">{t('picturesUpload.title')}</h2>
                            <p className="subtitle">{t('picturesUpload.subtitle')}</p>
                        </div>
                    </div>
                    <div className="step-screen__content__middle">
                        <h2 className="title">{t('picturesUpload.mainViews')}</h2>
                        <p className="help-text">{t('picturesUpload.helpText')}</p>
                        <div className="step-screen__content__middle__pictures_container">
                            <div className="row">
                                <DocumentUpload
                                    isFetching={fetchingImages.frontBody}
                                    file={complete.frontBody}
                                    onSelected={(file: File) => this.onFileSelected(file, 'frontBody')}
                                    onRemove={() => this.onFileRemoved('frontBody')}
                                    titleText={t('picturesUpload.photosTitle.frontBody')}
                                    extraText={t('picturesUpload.photosSubtitle.frontBody')}
                                    styles="purple glass-icon position-relative"
                                    iconPVC={ICON.FRONT_BODY}
                                    photoAngle={PhotoAngleType.FRONT_BODY}
                                />
                                <DocumentUpload
                                    isFetching={fetchingImages.leftBody}
                                    file={complete.leftBody}
                                    onSelected={(file: File) => this.onFileSelected(file, 'leftBody')}
                                    onRemove={() => this.onFileRemoved('leftBody')}
                                    titleText={t('picturesUpload.photosTitle.leftBody')}
                                    extraText={t('picturesUpload.photosSubtitle.leftBodyResume')}
                                    styles="purple glass-icon position-relative"
                                    iconPVC={ICON.RIGHT_BODY}
                                    photoAngle={PhotoAngleType.LEFT_BODY}
                                />
                                <DocumentUpload
                                    isFetching={fetchingImages.rightBody}
                                    file={complete.rightBody}
                                    onSelected={(file: File) => this.onFileSelected(file, 'rightBody')}
                                    onRemove={() => this.onFileRemoved('rightBody')}
                                    titleText={t('picturesUpload.photosTitle.rightBody')}
                                    extraText={t('picturesUpload.photosSubtitle.rightBodyResume')}
                                    styles="purple glass-icon position-relative"
                                    iconPVC={ICON.LEFT_BODY}
                                    photoAngle={PhotoAngleType.RIGHT_BODY}
                                />
                            </div>
                            <div className="row">
                                <DocumentUpload
                                    isFetching={fetchingImages.backBody}
                                    file={complete.backBody}
                                    onSelected={(file: File) => this.onFileSelected(file, 'backBody')}
                                    onRemove={() => this.onFileRemoved('backBody')}
                                    titleText={t('picturesUpload.photosTitle.backBody')}
                                    extraText={t('picturesUpload.photosSubtitle.backBody')}
                                    styles="purple glass-icon position-relative"
                                    iconPVC={ICON.BACK_BODY}
                                    photoAngle={PhotoAngleType.BACK_BODY}
                                />
                                <DocumentUpload
                                    isFetching={fetchingImages.ceiling}
                                    file={complete.ceiling}
                                    onSelected={(file: File) => this.onFileSelected(file, 'ceiling')}
                                    onRemove={() => this.onFileRemoved('ceiling')}
                                    titleText={t('picturesUpload.photosTitle.ceiling')}
                                    styles="purple glass-icon position-relative"
                                    iconPVC={ICON.CEILING}
                                />
                                <DocumentUpload
                                    isFetching={fetchingImages.frontWindshield}
                                    file={complete.frontWindshield}
                                    onSelected={(file: File) => this.onFileSelected(file, 'frontWindshield')}
                                    onRemove={() => this.onFileRemoved('frontWindshield')}
                                    titleText={t('picturesUpload.photosTitle.frontWindshield')}
                                    extraText={t('picturesUpload.photosSubtitle.frontWindshieldResume')}
                                    styles="dark-purple glass-icon position-relative"
                                    iconPVC={ICON.FRONT_WINDSHIELD}
                                    photoAngle={PhotoAngleType.FRONT_WINDSHIELD}
                                />
                            </div>
                            <div className="row">
                                <DocumentUpload
                                    isFetching={fetchingImages.frontRightWindshield}
                                    file={complete.frontRightWindshield}
                                    onSelected={(file: File) => this.onFileSelected(file, 'frontRightWindshield')}
                                    onRemove={() => this.onFileRemoved('frontRightWindshield')}
                                    titleText={t('picturesUpload.photosTitle.frontRightWindshield')}
                                    extraText={t('picturesUpload.photosSubtitle.frontRightWindshieldResume')}
                                    styles="dark-purple glass-icon position-relative"
                                    iconPVC={ICON.FRONT_RIGHT_WINDSHIELD}
                                    photoAngle={PhotoAngleType.FRONT_RIGHT_WINDSHIELD}
                                />
                                <DocumentUpload
                                    isFetching={fetchingImages.frontLeftWindshield}
                                    file={complete.frontLeftWindshield}
                                    onSelected={(file: File) => this.onFileSelected(file, 'frontLeftWindshield')}
                                    onRemove={() => this.onFileRemoved('frontLeftWindshield')}
                                    titleText={t('picturesUpload.photosTitle.frontLeftWindshield')}
                                    extraText={t('picturesUpload.photosSubtitle.frontLeftWindshieldResume')}
                                    styles="dark-purple glass-icon position-relative"
                                    iconPVC={ICON.FRONT_LEFT_WINDSHIELD}
                                    photoAngle={PhotoAngleType.FRONT_LEFT_WINDSHIELD}
                                />
                                <DocumentUpload
                                    isFetching={fetchingImages.inside}
                                    file={complete.inside}
                                    onSelected={(file: File) => this.onFileSelected(file, 'inside')}
                                    onRemove={() => this.onFileRemoved('inside')}
                                    titleText={t('picturesUpload.photosTitle.inside')}
                                    extraText={`(${t('picturesUpload.photosSubtitle.inside')})*`}
                                    styles="darker-purple glass-icon position-relative"
                                    iconPVC={ICON.INSIDE}
                                />
                            </div>
                            <div className="row">
                                <DocumentUpload
                                    isFetching={fetchingImages.identification}
                                    file={complete.identification}
                                    onSelected={(file: File) => this.onFileSelected(file, 'identification')}
                                    onRemove={() => this.onFileRemoved('identification')}
                                    titleText={`${t('picturesUpload.photosTitle.identification')} (${t('picturesUpload.photosSubtitle.identification')})**`}
                                    styles="blue glass-icon position-relative text-lower"
                                    bottomText={t('picturesUpload.bottomTitle.optional')}
                                    iconPVC={ICON.VIN}
                                />
                                <DocumentUpload
                                    isFetching={fetchingImages.document}
                                    file={complete.document}
                                    onSelected={(file: File) => this.onFileSelected(file, 'document')}
                                    onRemove={() => this.onFileRemoved('document')}
                                    titleText={`${t('picturesUpload.photosTitle.document')} (${t('picturesUpload.photosSubtitle.document')})***`}
                                    styles="blue glass-icon position-relative text-lower"
                                    bottomText={t('picturesUpload.bottomTitle.optional')}
                                    iconPVC={ICON.DOCUMENT}
                                />
                                <DocumentUpload
                                    isFetching={fetchingImages.inspection}
                                    file={complete.inspection}
                                    onSelected={(file: File) => this.onFileSelected(file, 'inspection')}
                                    onRemove={() => this.onFileRemoved('inspection')}
                                    titleText={`${t('picturesUpload.photosTitle.inspection')} (${t('picturesUpload.photosSubtitle.inspection')})`}
                                    styles="blue glass-icon position-relative text-lower"
                                    bottomText={t('picturesUpload.bottomTitle.optional')}
                                    iconPVC={ICON.DOCUMENT}
                                />
                            </div>
                            {hasFileTypeError && (
                                <div className="error">
                                    <p>{t('picturesUpload.errorFileType')}</p>
                                </div>
                            )}
                            {hasMandatoryError && (
                                <div className="error">
                                    <p>{t('picturesUpload.errorMandatoryDocuments')}</p>
                                </div>
                            )}
                            <p className="bold with-margin">{t('picturesUpload.withMotorRunning')}</p>
                            <p className="bold">{t('picturesUpload.vin')}</p>
                            <p>{t('picturesUpload.vinInformation')}</p>
                            <p className="bold with-top-margin">{t('picturesUpload.dua')}</p>
                        </div>
                    </div>
                    <div className="step-screen__content__middle without-border">
                        <h2 className="title">{t('picturesUpload.optionalPictures')}</h2>
                        <div className="step-screen__content__middle__pictures_container">
                            {count >= 0 && (
                                <div className="row with-margin">
                                    <DocumentUpload
                                        isFetching={fetchingImages.additionalPhotos0}
                                        file={complete.additionalPhotos[0] ? complete.additionalPhotos[0].url : ''}
                                        onSelected={(file: File) => this.onFileSelected(file, 'frontRightWindshield', true, 0)}
                                        onRemove={() => this.onFileRemoved('frontRightWindshield', true, 0)}
                                        buttonText={t('picturesUpload.photosTitle.frontRightWindshield')}
                                        helpText={t('picturesUpload.photosSubtitle.frontRightWindshield')}
                                        styles="position-relative"
                                        isOptional
                                        iconPVC={ICON.FRONT_RIGHT_WINDSHIELD}
                                    />
                                    <DocumentUpload
                                        isFetching={fetchingImages.additionalPhotos1}
                                        file={complete.additionalPhotos[1] ? complete.additionalPhotos[1].url : ''}
                                        onSelected={(file: File) => this.onFileSelected(file, 'frontLeftWindshield', true, 1)}
                                        onRemove={() => this.onFileRemoved('frontLeftWindshield', true, 1)}
                                        buttonText={t('picturesUpload.photosTitle.frontLeftWindshield')}
                                        helpText={t('picturesUpload.photosSubtitle.frontLeftWindshield')}
                                        styles="position-relative"
                                        isOptional
                                        iconPVC={ICON.FRONT_LEFT_WINDSHIELD}
                                    />
                                    <DocumentUpload
                                        isFetching={fetchingImages.additionalPhotos2}
                                        file={complete.additionalPhotos[2] ? complete.additionalPhotos[2].url : ''}
                                        onSelected={(file: File) => this.onFileSelected(file, 'inside', true, 2)}
                                        onRemove={() => this.onFileRemoved('inside', true, 2)}
                                        buttonText={t('picturesUpload.photosTitle.inside')}
                                        helpText={t('picturesUpload.photosSubtitle.inside')}
                                        styles="position-relative"
                                        isOptional
                                        iconPVC={ICON.INSIDE}
                                    />
                                </div>
                            )}
                            {count >= 3 && (
                                <div className="row">
                                    <DocumentUpload
                                        isFetching={fetchingImages.additionalPhotos3}
                                        file={complete.additionalPhotos[3] ? complete.additionalPhotos[3].url : ''}
                                        onSelected={(file: File) => this.onFileSelected(file, 'frontRightWindshield', true, 3)}
                                        onRemove={() => this.onFileRemoved('frontRightWindshield', true, 3)}
                                        buttonText={t('picturesUpload.photosTitle.frontRightWindshield')}
                                        helpText={t('picturesUpload.photosSubtitle.frontRightWindshield')}
                                        styles="position-relative"
                                        isOptional
                                        iconPVC={ICON.FRONT_RIGHT_WINDSHIELD}
                                    />
                                    <DocumentUpload
                                        isFetching={fetchingImages.additionalPhotos4}
                                        file={complete.additionalPhotos[4] ? complete.additionalPhotos[4].url : ''}
                                        onSelected={(file: File) => this.onFileSelected(file, 'frontLeftWindshield', true, 4)}
                                        onRemove={() => this.onFileRemoved('frontLeftWindshield', true, 4)}
                                        buttonText={t('picturesUpload.photosTitle.frontLeftWindshield')}
                                        helpText={t('picturesUpload.photosSubtitle.frontLeftWindshield')}
                                        styles="position-relative"
                                        isOptional
                                        iconPVC={ICON.FRONT_LEFT_WINDSHIELD}
                                    />
                                    <DocumentUpload
                                        isFetching={fetchingImages.additionalPhotos5}
                                        file={complete.additionalPhotos[5] ? complete.additionalPhotos[5].url : ''}
                                        onSelected={(file: File) => this.onFileSelected(file, 'inside', true, 5)}
                                        onRemove={() => this.onFileRemoved('inside', true, 5)}
                                        buttonText={t('picturesUpload.photosTitle.inside')}
                                        helpText={t('picturesUpload.photosSubtitle.inside')}
                                        styles="position-relative"
                                        isOptional
                                        iconPVC={ICON.INSIDE}
                                    />
                                </div>
                            )}
                            {count >= 6 && (
                                <div className="row">
                                    <DocumentUpload
                                        isFetching={fetchingImages.additionalPhotos6}
                                        file={complete.additionalPhotos[6] ? complete.additionalPhotos[6].url : ''}
                                        onSelected={(file: File) => this.onFileSelected(file, 'frontRightWindshield', true, 6)}
                                        onRemove={() => this.onFileRemoved('frontRightWindshield', true, 6)}
                                        buttonText={t('picturesUpload.photosTitle.frontRightWindshield')}
                                        helpText={t('picturesUpload.photosSubtitle.frontRightWindshield')}
                                        styles="position-relative"
                                        isOptional
                                        iconPVC={ICON.FRONT_RIGHT_WINDSHIELD}
                                    />
                                    <DocumentUpload
                                        isFetching={fetchingImages.additionalPhotos7}
                                        file={complete.additionalPhotos[7] ? complete.additionalPhotos[7].url : ''}
                                        onSelected={(file: File) => this.onFileSelected(file, 'frontLeftWindshield', true, 7)}
                                        onRemove={() => this.onFileRemoved('frontLeftWindshield', true, 7)}
                                        buttonText={t('picturesUpload.photosTitle.frontLeftWindshield')}
                                        helpText={t('picturesUpload.photosSubtitle.frontLeftWindshield')}
                                        styles="position-relative"
                                        isOptional
                                        iconPVC={ICON.FRONT_LEFT_WINDSHIELD}
                                    />
                                    <DocumentUpload
                                        isFetching={fetchingImages.additionalPhotos8}
                                        file={complete.additionalPhotos[8] ? complete.additionalPhotos[8].url : ''}
                                        onSelected={(file: File) => this.onFileSelected(file, 'inside', true, 8)}
                                        onRemove={() => this.onFileRemoved('inside', true, 8)}
                                        buttonText={t('picturesUpload.photosTitle.inside')}
                                        helpText={t('picturesUpload.photosSubtitle.inside')}
                                        styles="position-relative"
                                        isOptional
                                        iconPVC={ICON.INSIDE}
                                    />
                                </div>
                            )}
                            {count >= 9 && (
                                <div className="row">
                                    <DocumentUpload
                                        isFetching={fetchingImages.additionalPhotos9}
                                        file={complete.additionalPhotos[9] ? complete.additionalPhotos[9].url : ''}
                                        onSelected={(file: File) => this.onFileSelected(file, 'frontRightWindshield', true, 9)}
                                        onRemove={() => this.onFileRemoved('frontRightWindshield', true, 9)}
                                        buttonText={t('picturesUpload.photosTitle.frontRightWindshield')}
                                        helpText={t('picturesUpload.photosSubtitle.frontRightWindshield')}
                                        styles="position-relative"
                                        isOptional
                                        iconPVC={ICON.FRONT_RIGHT_WINDSHIELD}
                                    />
                                    <DocumentUpload
                                        isFetching={fetchingImages.additionalPhotos10}
                                        file={complete.additionalPhotos[10] ? complete.additionalPhotos[10].url : ''}
                                        onSelected={(file: File) => this.onFileSelected(file, 'frontLeftWindshield', true, 10)}
                                        onRemove={() => this.onFileRemoved('frontLeftWindshield', true, 10)}
                                        buttonText={t('picturesUpload.photosTitle.frontLeftWindshield')}
                                        helpText={t('picturesUpload.photosSubtitle.frontLeftWindshield')}
                                        styles="position-relative"
                                        isOptional
                                        iconPVC={ICON.FRONT_LEFT_WINDSHIELD}
                                    />
                                    <DocumentUpload
                                        isFetching={fetchingImages.additionalPhotos11}
                                        file={complete.additionalPhotos[11] ? complete.additionalPhotos[11].url : ''}
                                        onSelected={(file: File) => this.onFileSelected(file, 'inside', true, 11)}
                                        onRemove={() => this.onFileRemoved('inside', true, 11)}
                                        buttonText={t('picturesUpload.photosTitle.inside')}
                                        helpText={t('picturesUpload.photosSubtitle.inside')}
                                        styles="position-relative"
                                        isOptional
                                        iconPVC={ICON.INSIDE}
                                    />
                                </div>
                            )}
                            {hasOptionalFileTypeError && (
                                <div className="error">
                                    <p>{t('picturesUpload.errorFileType')}</p>
                                </div>
                            )}
                            {hasServerDownError && (
                                <div className="error">
                                    <p>{t('picturesUpload.errorServerDown')}</p>
                                </div>
                            )}
                            <p className="info">{t('picturesUpload.optionalPicturesInformation')}</p>
                        </div>
                        <div className="button-container">
                            {!this.isFetchingImage()
                                && (
                                <Button
                                    callback={this.onAdvancedClick}
                                    text={t('global.buttons.advance')}
                                    styles="btn--green btn--full-width"
                                    icon={ICON.CHEVRON_RIGHT}
                                    iconPosition="right"
                                />
                                )
                            }
                        </div>
                    </div>
                </div>
                <MessageBox message={t('picturesUpload.uploadingMessage')} showModal={showUploadingMessage} />
                <InvalidImageModal showModal={showModal} onModalClose={() => this.setState({ showModal: false })} invalidFileUrl={invalidFileUrl} />
            </div>
        );
    }
}

function mapStateToProps({ files, general }: AppState): StateProps {
    const { files: filesUpload, optionalFiles } = files;
    const { userToken } = general;

    return {
        files: filesUpload,
        optionalFiles,
        userToken: userToken || '',
    };
}

export default connect(mapStateToProps, {
    setFiles: setFilesActionCreator,
    setOptionalFiles: setOptionalFilesActionCreator,
})(withTranslationContext(withPreInspectionContext(FilesUploadScreen)));
