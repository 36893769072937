/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import logo from '../../../assets/images/logo_fluxe_vertical.svg';
import assistLogo from '../../../assets/images/fluxe_assist_logo.svg';
import { ICON, SvgIcon } from '../../elements/SvgIcon';
import Button from '../../elements/Button';
import { getUrlBackToWeb } from '../../../utils/misc';
 
 interface OwnProps extends RouteComponentProps, TranslationContext {
     isCheckOut: boolean;
     brokenGlassControlId?: number;
 }
 
const FinalScreen: React.FC<OwnProps> = ({ t, location, isCheckOut, brokenGlassControlId }: OwnProps) => {
    const { isError } = location?.state || {};
 
    const goBackToWeb = (): void => {
        const origin = sessionStorage.getItem('origin');
        window.location.assign(getUrlBackToWeb(origin, brokenGlassControlId));
    };

    const renderErrorMsg = () => {
        return isCheckOut ? t('checkOutFinal.errorMsg') : t('checkInFinal.errorMsg');
    };

    const renderSuccessMsg = () => {
        return isCheckOut ? t('checkOutFinal.successMsg') : t('checkInFinal.successMsg');
    };
 
    return (
        <div className="app-screen">
            <div className="top-bar" />
            <div className="app-screen__container">
                <div className="app-screen__container__welcome-page">
                    <img src={logo} alt="Fluxe Logo" />
                    <div className="centered">
                        <div className="final-icon-container">
                            <SvgIcon icon={isError ? ICON.STEP_1_ERROR : ICON.STEP_1_SUCCESS} />
                            <SvgIcon icon={isError ? ICON.STEP_2_ERROR : ICON.STEP_2_SUCCESS} />
                        </div>
                    </div>
                    <div className="bottom">
                        <img src={assistLogo} alt="Fluxe Assist Logo" />
                        <p className="bold">{isError ? renderErrorMsg() : renderSuccessMsg()}</p>
                    </div>
                    {isError ? (
                        <div className="bottom">
                            <SvgIcon icon={ICON.SUPPORT} />
                            <p>{t('expired.forMoreInformation')}</p>
                            <p>{t('expired.contactServiceInfo')}</p>
                            <h2>{t('expired.contactNumber')}</h2>
                            <p className="regular">{t('expired.helpText')}</p>
                        </div>
                    ) : (
                        <div className="app-screen__container__welcome-page__final-btn-wrapper">
                            <Button
                                callback={goBackToWeb}
                                text={t('checkInFinal.return')}
                                styles="btn--green btn--full-width"
                                icon={ICON.CHEVRON_RIGHT}
                                iconPosition="right"
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
 
export default withTranslationContext(withRouter(FinalScreen));
