/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { ICON, SvgIcon } from './SvgIcon';
import Loader from './Loader';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { Camera } from './CameraCanvas';
import { addOrientationListener, evaluateOrientation } from '../../utils/misc';
import { OrientationType } from '../../constants/general';
import BlockOrientation from '../screens/BlockOrientationScreen';

interface OwnProps {
    onSelected: (file: File) => void;
    onRemove: () => void;
    styles: string;
    photoAngle?: string;
    file?: string | null;
    buttonText?: string;
    helpText?: string;
    isOptional?: boolean;
    isGlass?: boolean;
    glassIcon?: string;
    extraText?: string;
    iconPVC?: string;
    mainStyle?: string;
    titleText?: string;
    bottomText?: string;
    isFetching?: boolean;
}

interface OwnState {
    file: string | null;
    showCamera: boolean;
    showModal: boolean;
    isLoadingCamera: boolean;
    orientation: string;
}

class DocumentUpload extends Component<TranslationContext & OwnProps, OwnState> {
    private readonly inputRef = React.createRef<HTMLInputElement>();

    constructor(props: OwnProps & TranslationContext) {
        super(props);

        this.state = {
            file: props.file || null,
            showCamera: false,
            showModal: false,
            isLoadingCamera: false,
            orientation: OrientationType.PORTRAIT,
        };
    }

    componentDidMount() {
        this.setState({
            orientation: evaluateOrientation(),
        });

        addOrientationListener((orientation: string) => {
            this.setState({
                orientation,
            });
        });
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>): void {
        const { file: oldFile } = prevProps;
        const { file: actualFile } = this.props;

        if (oldFile !== actualFile) {
            if (actualFile) {
                this.setState({
                    file: actualFile,
                });
            } else {
                this.setState({
                    file: null,
                });
            }
        }
    }

    onDocumentRemove = () => {
        const { onRemove } = this.props;
        onRemove();
    };

    onFileSelected = (file: File | null) => {
        const { onSelected } = this.props;
        if (!file) return;
        
        onSelected(file);
        this.setState({ showCamera: false });
    };

    closeCamera = () => {
        this.setState({
            showCamera: false,
            showModal: false,
        });
    }
    
    openCamera = () => {
        this.setState({ showCamera: true });
        if (!document.fullscreenElement) {
            if (!document.documentElement.requestFullscreen && (document.documentElement as any).webkitRequestFullscreen) {
                (document.documentElement as any).webkitRequestFullscreen();
            } else {
                document.documentElement?.requestFullscreen?.({ navigationUI: 'hide' })
                .catch(() => {});
            }
        }
    }

    toggleChooseMediaModal = () => {
        const { showModal } = this.state;
        this.setState({ showModal: !showModal });
    }

    openFileInput = () => {
        if (!this.inputRef.current) return;

        this.inputRef.current.click();
        this.toggleChooseMediaModal();
    }

    renderPreview = () => {
        const { file } = this.state;

        return (
            <div className="image-preview">
                <div className="icon-box" data-testid="remove-icon">
                    <SvgIcon icon={ICON.CROSS} callback={this.onDocumentRemove} />
                </div>
                <img src={file || ''} alt="" />
            </div>
        );
    };

    renderUpload = () => {
        const {
            isOptional,
            styles,
            helpText,
            buttonText,
            isGlass,
            glassIcon,
            extraText,
            iconPVC,
            titleText,
            bottomText,
            isFetching,
            t,
        } = this.props;

        if (isOptional) {
            return (
                <button
                    type="button"
                    className={`btn btn--upload-photo only-icon ${styles}`}
                    onClick={this.toggleChooseMediaModal}
                >
                    {isFetching ? (
                        <div className="loading-container">
                            <Loader />
                            <p>{t('global.loading')}</p>
                        </div>
                    ) : <SvgIcon icon={ICON.PLUS_CIRCLE} />}
                </button>
            );
        }

        if (isGlass) {
            return (
                <button
                    type="button"
                    className={`btn btn--upload-photo glass-icon ${styles}`}
                    onClick={this.toggleChooseMediaModal}
                >
                    {glassIcon && (
                        <SvgIcon icon={glassIcon || ''} />
                    )}
                </button>
            );
        }

        if (isFetching) {
            return (
                <button
                    type="button"
                    className={`btn btn--upload-photo ${styles}`}
                    onClick={this.toggleChooseMediaModal}
                >
                    <SvgIcon icon={iconPVC || ICON.PLUS} />
                    <div className="loading-container">
                        <Loader />
                        <p>{t('global.loading')}</p>
                    </div>
                </button>
            );
        }

        return (
            <button
                type="button"
                className={`btn btn--upload-photo ${styles}`}
                onClick={this.toggleChooseMediaModal}
            >
                <SvgIcon icon={iconPVC || ICON.PLUS} />
                <div className="more-top">+</div>
                <div className="title-top">
                    <p className="text-small">{titleText}</p>
                    {extraText && <p className="text-small">{extraText}</p>}
                </div>
                <div>
                    <p className="uppercase">{buttonText}</p>
                    <p className="bold">{helpText}</p>
                </div>
                <div className="bottom">
                    <p>{bottomText}</p>
                </div>
            </button>
        );
    };

    renderModal = () => {
        const { t } = this.props;
        const { orientation, showCamera } = this.state;

        if (orientation === OrientationType.PORTRAIT && showCamera) {
            return (
                <div className="modal">
                    <BlockOrientation />
                </div>
            );
        }

        return (
            <div className="modal">
                <div className="modal__container document-upload">
                    <div className="info-container">
                        <button
                            type="button"
                            className="btn btn--upload-photo"
                            onClick={this.openFileInput}
                        >
                            <SvgIcon icon={ICON.PLUS} />
                            <div>
                                <p className="uppercase">{t('picturesUpload.intermediaryModal.gallery')}</p>
                            </div>
                        </button>
                        <button
                            type="button"
                            className="btn btn--upload-photo"
                            onClick={this.openCamera}
                        >
                            <SvgIcon icon={ICON.PLUS} />
                            <div>
                                <p className="uppercase">{t('picturesUpload.intermediaryModal.camera')}</p>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { mainStyle, photoAngle } = this.props;
        const {
            file, showCamera, showModal, orientation,
        } = this.state;
        
        return (
            <div className={`document-upload ${mainStyle || ''}`} data-testid="document-upload">
                { showCamera && orientation === OrientationType.LANDSCAPE && <Camera photoAngle={photoAngle || ''} onFileSelected={this.onFileSelected} close={this.closeCamera} /> }
                { showModal && this.renderModal() }
                <input
                    ref={this.inputRef}
                    type="file"
                    name="file"
                    accept="image/*"
                    onChange={e => this.onFileSelected(e.currentTarget.files ? e.currentTarget.files[0] : null)}
                />
                <div>
                    { file ? this.renderPreview() : this.renderUpload() }
                </div>
            </div>
        );
    }
}

export default withTranslationContext(DocumentUpload);
