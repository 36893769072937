/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

import {
    PreferencesAction, PreferencesActionTypes,
} from './preferences_types';

export const languageUpdateActionCreator = (lang : string): PreferencesActionTypes => {   
    return {
        type: PreferencesAction.SET_LANGUAGE,
        payload: lang,
    }
}

export const setLanguage = (lang: string) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(languageUpdateActionCreator(lang));
    }
};
