/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */
import {
    GeneralAction,
    GeneralActionType,
} from './general_types';

import { VideoData } from '../constants/general';

export const setUserTokenActionCreator = (userToken: string): GeneralActionType => {
    return {
        type: GeneralAction.SET_USER_TOKEN,
        payload: userToken,
    };
};

export const setVideoDataActionCreator = (videoData: VideoData): GeneralActionType => {
    return {
        type: GeneralAction.SET_VIDEO_DATA,
        payload: videoData,
    };
};
