/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import logo from '../../assets/images/logo_fluxe_vertical.svg';
import axios from 'axios';
import moment from 'moment';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { PreInspectionContext, withPreInspectionContext } from '../controllers/preInspection/PreInspectionContext';
import Button from '../elements/Button';
import { MatchParams, PreInspection, PreInspectionType } from '../../constants/misc';
import {
    INFORMATION_GLASS_ROUTE,
    NOT_FOUND_ROUTE,
    TOKEN_EXPIRED_ROUTE,
    WELCOME_COMPLETE_ROUTE,
} from '../../constants/routes';
import { preInspectionsCompleteStartURL } from '../../services/pre_inspections';
import Loader from '../elements/Loader';
import { setUserTokenActionCreator } from '../../actions/general';
import { AdditionalImage } from '../../reducers/types';

interface OwnProps extends TranslationContext, RouteComponentProps<MatchParams>, PreInspectionContext {}

interface DispatchProps {
    setUserToken: Function;
}

interface OwnState {
    userToken: string;
    preparing: boolean;
    preInspection: PreInspection;
}

type Props = OwnProps & DispatchProps;

const initialState: OwnState = {
    userToken: '',
    preparing: false,
    preInspection: {
        uid: '',
        simulationNumber: '',
        insured: '',
        insuranceCompanyName: '',
        vehicleBrand: '',
        vehicleLicensePlate: '',
        insuredEmail: '',
        vehicleModel: '',
        simulationDate: null,
        type: '',
    },
};

class HomeScreen extends Component<Props, OwnState> {
    state = initialState;

    componentDidMount(): void {
        const { match, history } = this.props;
        const { params } = match;
        const { token } = params;

        if (token) {
            this.setState({
                userToken: token,
            }, () => this.prepare());
        } else {
            history.push(NOT_FOUND_ROUTE);
        }
    }

    onBeginClick = () => {
        const { history } = this.props;
        const { preInspection } = this.state;

        if (preInspection.type === PreInspectionType.COMPLETE) {
            history.push(WELCOME_COMPLETE_ROUTE);
        } else {
            history.push(INFORMATION_GLASS_ROUTE);
        }
    };

    prepare = async () => {
        const { history, setUserToken, setGlassPreInspectionPhotos, setCompletePreInspectionPhotos } = this.props;
        const { preparing, userToken } = this.state;

        if (preparing) return;

        this.setState({ preparing: true });

        await axios.get(preInspectionsCompleteStartURL(userToken))
            .then(response => {
                const { data } = response;

                if (data) {
                    const preInspection: PreInspection = {
                        ...data.preInspection,
                        insuranceCompanyName: data.preInspection.insuranceCompany ? data.preInspection.insuranceCompany.name : '',
                    };

                    setUserToken(userToken);

                    const photos = this.setPhotosFromData(data);

                    if (data.preInspection.type === PreInspectionType.GLASS) {
                        setGlassPreInspectionPhotos('frontLeftWindshield', photos.frontLeftWindshield);
                        setGlassPreInspectionPhotos('frontRightWindshield', photos.frontRightWindshield);
                        setGlassPreInspectionPhotos('frontWindshield', photos.frontWindshield);
                        setGlassPreInspectionPhotos('licensePlateWindshield', photos.licensePlateWindshield);
                    } else {
                        setCompletePreInspectionPhotos('additionalPhotos', photos.additionalPhotos);
                        setCompletePreInspectionPhotos('backBody', photos.backBody);
                        setCompletePreInspectionPhotos('ceiling', photos.ceiling);
                        setCompletePreInspectionPhotos('document', photos.document);
                        setCompletePreInspectionPhotos('frontBody', photos.frontBody);
                        setCompletePreInspectionPhotos('frontLeftWindshield', photos.frontLeftWindshield);
                        setCompletePreInspectionPhotos('frontRightWindshield', photos.frontRightWindshield);
                        setCompletePreInspectionPhotos('frontWindshield', photos.frontWindshield);
                        setCompletePreInspectionPhotos('identification', photos.identification);
                        setCompletePreInspectionPhotos('inside', photos.inside);
                        setCompletePreInspectionPhotos('inspection', photos.inspection);
                        setCompletePreInspectionPhotos('leftBody', photos.leftBody);
                        setCompletePreInspectionPhotos('rightBody', photos.rightBody);
                    }

                    this.setState({
                        preInspection,
                        preparing: false,
                    })
                }
            })
            .catch((e) => {
                history.push(TOKEN_EXPIRED_ROUTE);
            });
    };

    setPhotosFromData = (data: any) => {
        let frontWindshieldPhoto = null;
        let frontRightWindshieldPhoto = null;
        let frontLeftWindshieldPhoto = null;
        let licensePlatePhoto = null;
        let identificationPhoto = null;
        let insidePhoto = null;
        let inspectionPhoto = null;
        let leftBodyPhoto = null;
        let backBodyPhoto = null;
        let ceilingPhoto = null;
        let documentPhoto = null;
        let frontBodyPhoto = null;
        let rightBodyPhoto = null;
        let additionalPhotos: Array<AdditionalImage> = [];


        if (data.additionalPhotos && data.additionalPhotos.lenght > 0) {
            data.additionalPhotos.forEach((d: any) => {
                additionalPhotos.push({
                    id: d.id,
                    url: d.url,
                });
            })
        }

        if (data.rightBody && data.rightBody.url) {
            rightBodyPhoto = data.rightBody.url;
        }

        if (data.frontLeftWindshield && data.frontLeftWindshield.url) {
            frontLeftWindshieldPhoto = data.frontLeftWindshield.url;
        }

        if (data.frontRightWindshield && data.frontRightWindshield.url) {
            frontRightWindshieldPhoto = data.frontRightWindshield.url;
        }

        if (data.frontWindshield && data.frontWindshield.url) {
            frontWindshieldPhoto = data.frontWindshield.url;
        }

        if (data.licensePlateWindshield && data.licensePlateWindshield.url) {
            licensePlatePhoto = data.licensePlateWindshield.url;
        }

        if (data.identification && data.identification.url) {
            identificationPhoto = data.identification.url;
        }

        if (data.inside && data.inside.url) {
            insidePhoto = data.inside.url;
        }

        if (data.inspection && data.inspection.url) {
            inspectionPhoto = data.inspection.url;
        }

        if (data.leftBody && data.leftBody.url) {
            leftBodyPhoto = data.leftBody.url;
        }

        if (data.backBody && data.backBody.url) {
            backBodyPhoto = data.backBody.url;
        }

        if (data.ceiling && data.ceiling.url) {
            ceilingPhoto = data.ceiling.url;
        }

        if (data.document && data.document.url) {
            documentPhoto = data.document.url;
        }

        if (data.frontBody && data.frontBody.url) {
            frontBodyPhoto = data.frontBody.url;
        }

        return {
            frontWindshield: frontWindshieldPhoto,
            frontRightWindshield: frontRightWindshieldPhoto,
            frontLeftWindshield: frontLeftWindshieldPhoto,
            licensePlateWindshield: licensePlatePhoto,
            additionalPhotos: additionalPhotos,
            backBody: backBodyPhoto,
            ceiling: ceilingPhoto,
            document: documentPhoto,
            frontBody: frontBodyPhoto,
            identification: identificationPhoto,
            inside: insidePhoto,
            inspection: inspectionPhoto,
            leftBody: leftBodyPhoto,
            rightBody: rightBodyPhoto,
        }
    }

    render() {
        const { t } = this.props;
        const { preInspection, preparing } = this.state;

        let date: string = '';
        if (preInspection.simulationDate) {
            date = moment(preInspection.simulationDate).format('DD/MM/YYYY');
        }

        return (
            <div className="app-screen">
                {preparing && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                <div className="app-screen__container">
                    <div className="app-screen__container__entry-page">
                        <img src={logo} alt="Fluxe Logo" />
                        <div className="centered">
                            <p className="big-text">{t('home.hello')}</p>
                            <p className="small-text">{t('home.helloMessage')}</p>
                            <p className="semibold">{t('home.simulationNumber', { id: preInspection.simulationNumber || '' })}</p>
                            <Button
                                text={t('home.beginBtn')}
                                styles="btn--green btn--full-width"
                                callback={this.onBeginClick}
                            />
                            <p>{t('home.helpMessage')}</p>
                        </div>
                        <div className="information">
                            <div className="item">
                                <p className="label">{t('home.simulationDate')}</p>
                                <p className="semibold">&nbsp; {date}</p>
                            </div>
                            <div className="item">
                                <p className="label">{t('home.policyHolder')}</p>
                                <p className="semibold">&nbsp; {preInspection.insured}</p>
                            </div>
                            <div className="item with-margin">
                                <p className="label">{t('home.contactEmail')}</p>
                                <p className="semibold">&nbsp; {preInspection.insuredEmail}</p>
                            </div>
                            <div className="item">
                                <p className="label">{t('home.insurance')}</p>
                                <p className="semibold">&nbsp; {preInspection.insuranceCompanyName}</p>
                            </div>
                            <div className="item">
                                <p className="label">{t('home.vehicle')}</p>
                                <p className="semibold">&nbsp; {`${preInspection.vehicleBrand} ${preInspection.vehicleModel}`}</p>
                            </div>
                            <div className="item">
                                <p className="label">{t('home.licensePlate')}</p>
                                <p className="semibold">&nbsp; {preInspection.vehicleLicensePlate}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(null, {
    setUserToken: setUserTokenActionCreator,
})(
    withTranslationContext(
        withPreInspectionContext(HomeScreen)
    )
);
