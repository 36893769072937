/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { FilesAction, FilesActionType } from './files_types';
import { FilesUpload } from '../constants/misc';

export const setOptionalFilesActionCreator = (files: File[]): FilesActionType => {
    return {
        type: FilesAction.SET_OPTIONAL_FILES,
        payload: files,
    }
};

export const setFilesActionCreator = (files: FilesUpload): FilesActionType => {
    return {
        type: FilesAction.SET_FILES,
        payload: files,
    }
};
