/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { REHYDRATE } from 'redux-persist';

import { GeneralState } from './types';
import { GeneralAction } from '../actions/general_types';

/**
 * @typedef {GeneralState} generalInitialState
 */
export const generalInitialState: GeneralState = {
    userToken: '',
    videoData: {
        token: '',
        preInspectionId: 0,
        preInspectionUid: '',
    },
};

/**
 * general reducer
 * @param {GeneralState} state
 * @param {any} action
 * @returns {GeneralState}
 */
export default function (state = generalInitialState, action: any): GeneralState {
    switch (action.type) {
        case REHYDRATE:
            if (action.payload && action.payload.general) {
                return {
                    ...state,
                    ...action.payload.general,
                };
            }
            return state;
        case GeneralAction.SET_USER_TOKEN:
            return {
                ...state,
                userToken: action.payload,
            };
        case GeneralAction.SET_VIDEO_DATA:
            return {
                ...state,
                videoData: action.payload,
            };
        default:
            return state;
    }
}
