/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { VideoData } from '../constants/general';

export enum GeneralAction {
    SET_VIDEO_DATA = 'SET_VIDEO_DATA',
    SET_USER_TOKEN = 'SET_USER_TOKEN',
}

export interface SetUserToken {
    type: typeof GeneralAction.SET_USER_TOKEN;
    payload: string;
}

export interface SetVideoDataAction {
    type: typeof GeneralAction.SET_VIDEO_DATA;
    payload: VideoData;
}

export type GeneralActionType = SetVideoDataAction | SetUserToken;
