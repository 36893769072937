/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';

export const preInspectionsCompleteStartURL = (userToken: string) => {
    return `${API_URL}/pre-inspections/${userToken}/start`;
};

export const preInspectionsCompleteFinalizeURL = (userToken: string) => {
    return `${API_URL}/pre-inspections/complete/mobile/${userToken}/finalize`;
};

export const preInspectionsGlassFinalizeURL = (userToken: string) => {
    return `${API_URL}/pre-inspections/glass/mobile/${userToken}`;
};

export const preInspectionsCompleteRemoveAdditionalFile = (userToken: string, fileId: string) => {
    return `${API_URL}/pre-inspections/complete/mobile/${userToken}/remove/ADDITIONAL_PHOTOS/${fileId}`;
}

export const preInspectionsCompleteUpload = (userToken: string, fileType: string) => {
    return `${API_URL}/pre-inspections/complete/mobile/${userToken}/upload/${fileType}`;
}

export const preInspectionsGlassURL = (userToken: string, fileType: string) => {
    return `${API_URL}/pre-inspections/glass/mobile/${userToken}/${fileType}`;
};

export const preInspectionVerifyToken = (userId: number | string): string => {
    return `${API_URL}/pre-inspections/video/verify/${userId}`;
}
