/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { ICON, SvgIcon } from './SvgIcon';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';

interface OwnProps extends TranslationContext {
    showModal: boolean;
    onModalClose: Function;
    invalidFileUrl: string;
    containerStyle?: string;
    boxContentStyle?: string;
    boxStyle?: string;
}

const modalOutsideId: string = 'modal-outside';

class InvalidImageModal extends Component<OwnProps> {
    componentDidMount(): void {
        document.addEventListener('mousedown', this.handleOutsideClick);
    }

    componentWillUnmount(): void {
        document.removeEventListener('mousedown', this.handleOutsideClick);
    }

    handleOutsideClick = (e: any) => {
        const { target } = e;
        const { id } = target;

        if (id === modalOutsideId) {
            this.onClose();
        }
    };

    onClose = () => {
        const { onModalClose } = this.props;
        onModalClose();
    };

    render() {
        const { showModal, invalidFileUrl, t } = this.props;

        if (!showModal) return null;

        return (
            <div id={modalOutsideId} className="modal">
                <div className="modal__container">
                    <div className="image-container">
                        <div className="top-container">
                            <div className="error-container">
                                <p className="red">{t('modal.image.invalidPhoto')}</p>
                                <p>{t('modal.image.invalidPhotoDescription')}</p>
                            </div>
                            <div className="icon-box">
                                <SvgIcon icon={ICON.CROSS} callback={this.onClose} />
                            </div>
                        </div>
                        <img src={invalidFileUrl} alt="" />
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslationContext(InvalidImageModal);
