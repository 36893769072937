/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { isNil } from 'lodash';
import React, { useEffect } from 'react';
import { ApiFile } from '../../constants/types';
import DocumentUpload from './DocumentUpload';

interface OwnProps {
    onFileSelected: (file: File, index: number) => void;
    onFileDeleted: (file: ApiFile | null) => void;
    files: ApiFile[];
    maximumFiles: number;
}

const AdditionalPhotos: React.FC<OwnProps> = (props: OwnProps) => {
    const {
        files, onFileDeleted, onFileSelected, maximumFiles,
    } = props;
    
    const [fetchingPhotos, setFetchingPhotos] = React.useState<number[]>([]);
    const [additionalFiles, setAdditionalFiles] = React.useState<(ApiFile | null)[]>(Array.from({ length: maximumFiles }).map((_, i) => files[i] || null));
    
    useEffect(() => {
        const newFetchingFiles = [...fetchingPhotos];
        const newAdditionalFiles = [...additionalFiles];

        // added files
        const addedFiles = files.filter(file => !additionalFiles.find(f => f?.id === file.id));
        addedFiles.forEach(file => {
            if (isNil(file.index)) return;
            newFetchingFiles.splice(newFetchingFiles.indexOf(file.index), 1);
            newAdditionalFiles[file.index] = file;
        });

        // deleted files
        const deletedFiles = additionalFiles.filter(file => !files.find(f => f.id === file?.id));
        deletedFiles.forEach(file => {
            if (!file || isNil(file.index)) return;
            newFetchingFiles.splice(newFetchingFiles.indexOf(file.index), 1);
            newAdditionalFiles[file.index] = null;
        });
        
        setAdditionalFiles(newAdditionalFiles);
        setFetchingPhotos(newFetchingFiles);
        // eslint-disable-next-line
    }, [files.length]);
    
    return (
        <React.Fragment>
            {
                additionalFiles.map((file, i) => {
                    return (
                        <DocumentUpload
                            key={file?.id || i}
                            styles="position-relative"
                            isFetching={fetchingPhotos.includes(i)}
                            file={file?.fileString}
                            onSelected={(newFile: File): void => {
                                onFileSelected(newFile, i);
                                setFetchingPhotos([...fetchingPhotos, i]);
                            }}
                            onRemove={(): void => {
                                onFileDeleted(file);
                                setFetchingPhotos([...fetchingPhotos, i]);
                            }}
                            isOptional
                        />
                    );
                })
            }
            
        </React.Fragment>
    );
};
   
export default AdditionalPhotos;
