/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import axios from 'axios';
import logo from '../../../assets/images/logo_horizontal.svg';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import assistLogo from '../../../assets/images/fluxe_assist_logo.svg';
import DocumentUpload from '../../elements/DocumentUpload';
import {
    FilesUpload, GlassStatusResponse, MatchParams, PhotoAngleType,
} from '../../../constants/misc';
import { AppState } from '../../../reducers/types';
import { setFilesActionCreator } from '../../../actions/files';
import { ICON } from '../../elements/SvgIcon';
import Button from '../../elements/Button';
import {
    FINAL_GLASS_ERROR_ROUTE,
    FINAL_GLASS_SUCCESS_ROUTE,
    NOT_FOUND_ROUTE,
} from '../../../constants/routes';
import { preInspectionsGlassFinalizeURL, preInspectionsGlassURL } from '../../../services/pre_inspections';
import { PreInspectionContext, withPreInspectionContext } from '../../controllers/preInspection/PreInspectionContext';
import { parseStringType } from '../../../utils/misc';
import InvalidImageModal from '../../elements/InvalidImageModal';
import MessageBox from '../../elements/MessageBox';
import Loader from '../../elements/Loader';

interface OwnProps extends RouteComponentProps<MatchParams>, TranslationContext {}

interface DispatchProps {
    setFiles: Function;
    setOptionalFiles: Function;
}

interface StateProps {
    files: FilesUpload;
    userToken: string;
}

type Props = OwnProps & StateProps & DispatchProps & PreInspectionContext;

interface OwnState {
    hasMandatoryError: boolean;
    hasFileTypeError: boolean;
    hasNotFoundError: boolean;
    hasServerDownError: boolean;
    fetchingImages: any;
    showModal: boolean;
    invalidFileUrl: string;
    isFetching: boolean;
    showUploadingMessage: boolean;
}

const initialState: OwnState = {
    hasMandatoryError: false,
    hasFileTypeError: false,
    hasNotFoundError: false,
    hasServerDownError: false,
    fetchingImages: {
        frontLeftWindshield: false,
        frontRightWindshield: false,
        frontWindshield: false,
        licensePlateWindshield: false,
    },
    showModal: false,
    invalidFileUrl: '',
    isFetching: false,
    showUploadingMessage: false,
};

class GlassWelcomeScreen extends Component<Props, OwnState> {
    state = initialState;

    componentDidMount(): void {
        const { userToken, history } = this.props;
         
        if (!userToken || userToken === '') {
            history.push(NOT_FOUND_ROUTE);
        }
    }

    onFileSelected = (file: File, type: string) => {
        const { userToken, setGlassPreInspectionPhotos } = this.props;
        const { type: fileType } = file;
        const { fetchingImages } = this.state;

        if (fetchingImages[type]) return;

        if (!fileType.startsWith('image') || String(fileType).includes('svg')) {
            this.setState({ hasFileTypeError: true });
            return;
        }

        const config = {
            headers: {
                'content-type': 'application/octet-stream',
            },
        };

        const invalidFileUrl = URL.createObjectURL(file);

        this.setState({
            fetchingImages: {
                ...this.state.fetchingImages,
                [type]: true,
            },
        });

        axios.post(preInspectionsGlassURL(userToken, parseStringType(type)), file, config).then(response => {
            this.setState({
                fetchingImages: {
                    ...this.state.fetchingImages,
                    [type]: false,
                },
                hasFileTypeError: false,
                hasServerDownError: false,
                hasMandatoryError: false,
                hasNotFoundError: false,
            });

            setGlassPreInspectionPhotos(type, response.data[type].url);
        }).catch(error => {
            this.setState({
                fetchingImages: {
                    ...this.state.fetchingImages,
                    [type]: false,
                },
            });
            if (error && error.response) {
                const { status, data } = error.response;

                if (status === 400 && data.errors.length > 0) {
                    const { errors } = data;

                    errors.forEach((e: any) => {
                        if (e.errorCode === 29) {
                            this.setState({
                                showModal: true,
                                invalidFileUrl,
                            });
                        }
                    });
                }

                if (status === 502) {
                    this.setState({
                        hasServerDownError: true,
                    });
                }
            }
        });
    };

    onFileRemoved = (type: string) => {
        const { setGlassPreInspectionPhotos } = this.props;
        setGlassPreInspectionPhotos(type, null);
    };

    onAdvancedClick = () => {
        const { glass } = this.props;
        const {
            frontWindshield, frontLeftWindshield, frontRightWindshield, licensePlateWindshield,
        } = glass;

        if (!frontRightWindshield || !frontWindshield || !frontLeftWindshield || !licensePlateWindshield) {
            this.setState({ hasMandatoryError: true });
        } else {
            this.setState({
                hasMandatoryError: false,
                hasFileTypeError: false,
                hasNotFoundError: false,
            }, () => this.preInspectionFinalizeRequest());
        }
    };

    isFetchingImage = () => {
        const { fetchingImages } = this.state;

        let isFetching = false;

        Object.keys(fetchingImages).forEach(key => {
            if (fetchingImages[key]) isFetching = true;
        });

        return isFetching;
    }

    preInspectionFinalizeRequest = async () => {
        const { history, userToken } = this.props;
        const { isFetching } = this.state;
        if (this.isFetchingImage() || isFetching) return;

        this.setState({ isFetching: true, showUploadingMessage: true });
        axios.post(preInspectionsGlassFinalizeURL(userToken))
            .then(response => {
                const { data } = response;

                this.setState({
                    showUploadingMessage: false,
                    isFetching: false,
                });

                switch (data) {
                    case GlassStatusResponse.DAMAGED:
                        history.push(FINAL_GLASS_ERROR_ROUTE);
                        break;
                    case GlassStatusResponse.INVALID:
                        history.push(FINAL_GLASS_ERROR_ROUTE);
                        break;
                    case GlassStatusResponse.OK:
                        history.push(FINAL_GLASS_SUCCESS_ROUTE);
                        break;
                    default:
                }
            })
            .catch(error => {
                if (error.response && error.response.status && error.respose.status < 500) {
                    this.setState({
                        hasNotFoundError: true,
                        showUploadingMessage: false,
                        isFetching: false,
                    });
                    return;
                }

                this.setState({
                    hasServerDownError: true,
                    showUploadingMessage: false,
                    isFetching: false,
                });
            });
    };

    render() {
        const { t, glass } = this.props;
        const {
            hasFileTypeError,
            hasMandatoryError,
            hasNotFoundError,
            fetchingImages,
            hasServerDownError,
            showModal,
            invalidFileUrl,
            isFetching,
            showUploadingMessage,
        } = this.state;

        return (
            <div className="step-screen">
                {showUploadingMessage && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                <div className="step-screen__top-bar">
                    <img src={logo} alt="Fluxe Logo" />
                    <p className="title">{t('global.fluxeName')}</p>
                </div>
                <div className="step-screen__content glass-welcome">
                    <div className="title-container">
                        <h4>{t('welcome.welcomeMsg')}</h4>
                        <img src={assistLogo} alt="Fluxe Assist Logo" />
                        <h3>{t('welcome.glass')}</h3>
                        <h3 className="lowercase">{t('welcome.infoMessageGlass')}</h3>
                    </div>

                    <div className="step-screen__content__middle without-border">
                        <div className="step-screen__content__middle__pictures_container glass-pictures">
                            <div className="row">
                                <DocumentUpload
                                    isFetching={fetchingImages.frontLeftWindshield}
                                    file={glass.frontLeftWindshield}
                                    onSelected={(file: File) => this.onFileSelected(file, 'frontLeftWindshield')}
                                    onRemove={() => this.onFileRemoved('frontLeftWindshield')}
                                    titleText={t('picturesUpload.photosTitle.frontLeftWindshield')}
                                    extraText={t('picturesUpload.photosSubtitle.frontLeftWindshield')}
                                    styles="glass-icon position-relative no-top-margin"
                                    iconPVC={ICON.FRONT_LEFT_WINDSHIELD}
                                    photoAngle={PhotoAngleType.FRONT_LEFT_WINDSHIELD}
                                />
                                <DocumentUpload
                                    isFetching={fetchingImages.frontWindshield}
                                    file={glass.frontWindshield}
                                    onSelected={(file: File) => this.onFileSelected(file, 'frontWindshield')}
                                    onRemove={() => this.onFileRemoved('frontWindshield')}
                                    titleText={t('picturesUpload.photosTitle.frontWindshield')}
                                    extraText={t('picturesUpload.photosSubtitle.frontWindshield')}
                                    styles="purple glass-icon position-relative no-top-margin"
                                    iconPVC={ICON.FRONT_WINDSHIELD}
                                    photoAngle={PhotoAngleType.FRONT_WINDSHIELD}
                                />
                                <DocumentUpload
                                    isFetching={fetchingImages.frontRightWindshield}
                                    file={glass.frontRightWindshield}
                                    onSelected={(file: File) => this.onFileSelected(file, 'frontRightWindshield')}
                                    onRemove={() => this.onFileRemoved('frontRightWindshield')}
                                    titleText={t('picturesUpload.photosTitle.frontRightWindshield')}
                                    extraText={t('picturesUpload.photosSubtitle.frontRightWindshield')}
                                    styles="purple glass-icon position-relative no-top-margin"
                                    iconPVC={ICON.FRONT_RIGHT_WINDSHIELD}
                                    photoAngle={PhotoAngleType.FRONT_RIGHT_WINDSHIELD}
                                />
                                
                            </div>
                            <div style={{
                                minWidth: '330px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                            >
                                <div className="upload-container centered">
                                    <DocumentUpload
                                        isFetching={fetchingImages.licensePlateWindshield}
                                        file={glass.licensePlateWindshield}
                                        onSelected={(file: File) => this.onFileSelected(file, 'licensePlateWindshield')}
                                        onRemove={() => this.onFileRemoved('licensePlateWindshield')}
                                        titleText={t('picturesUpload.photosTitle.licensePlateWindshield')}
                                        extraText={t('picturesUpload.photosSubtitle.licensePlateWindshield')}
                                        styles="purple glass-icon position-relative no-top-margin"
                                        iconPVC={ICON.LICENSE_PLATE_WINDSHIELD}
                                        photoAngle={PhotoAngleType.FRONT_BODY}
                                    />
                                </div>
                            </div>
                            {hasFileTypeError && (
                                <div className="error">
                                    <p>{t('picturesUpload.errorFileType')}</p>
                                </div>
                            )}
                            {hasMandatoryError && (
                                <div className="error">
                                    <p>{t('picturesUpload.errorMandatoryDocs')}</p>
                                </div>
                            )}
                            {hasNotFoundError && (
                                <div className="error">
                                    <p>{t('picturesUpload.errorNotFound')}</p>
                                </div>
                            )}
                            {hasServerDownError && (
                                <div className="error">
                                    <p>{t('picturesUpload.errorServerDown')}</p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="button-container">
                        {(!this.isFetchingImage() && !isFetching)
                            && (
                            <Button
                                callback={this.onAdvancedClick}
                                text={t('global.buttons.advance')}
                                styles="btn--green btn--full-width"
                                icon={ICON.CHEVRON_RIGHT}
                                iconPosition="right"
                            />
                            )
                        }
                    </div>
                </div>
                <MessageBox message={t('picturesUpload.uploadingMessage')} showModal={showUploadingMessage} />
                <InvalidImageModal showModal={showModal} onModalClose={() => this.setState({ showModal: false })} invalidFileUrl={invalidFileUrl} />
            </div>
        );
    }
}

function mapStateToProps({ files, general }: AppState): StateProps {
    const { files: filesUpload } = files;
    const { userToken } = general;

    return {
        files: filesUpload,
        userToken: userToken || '',
    };
}

export default connect(mapStateToProps, {
    setFiles: setFilesActionCreator,
})(withTranslationContext(
    withPreInspectionContext(GlassWelcomeScreen),
));
