import React, { FunctionComponent } from 'react';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { SvgIcon, ICON } from './SvgIcon';
import goodCarImg from '../../assets/images/car-good.svg';
import badCarImg from '../../assets/images/car-bad.svg';
import Button from './Button';

interface OwnProps extends TranslationContext {
    hideBrowserWarning?: boolean;
    onConfirm(): void;
}

const BeforeProceedingMessage: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const { t, hideBrowserWarning, onConfirm } = props;
    
    return (
        <div className="information-screen">
            <div className="top-bar" />
            <h1>{t('glassInformation.title')}</h1>
            <div className="info-item">
                <SvgIcon icon={ICON.LIGHT_BULB} />
                <p>{t('glassInformation.light')}</p>
            </div>
            <div className="info-item">
                <SvgIcon icon={ICON.VISION} />
                <p>{t('glassInformation.camera')}</p>
            </div>
            <div className="info-item">
                <SvgIcon icon={ICON.LIGHT_BULB} />
                <p>{t('glassInformation.glass')}</p>
            </div>
            <div className="info-item">
                <div className="images">
                    <img src={goodCarImg} alt="" />
                    <img src={badCarImg} alt="" />
                </div>
                <p>{t('glassInformation.angle')}</p>
            </div>
            {
                !hideBrowserWarning && (
                    <div className="info-item">
                        <p>{t('glassInformation.safari')}</p>
                    </div>
                )
            }
            <Button
                callback={onConfirm}
                text={t('global.buttons.understood')}
                styles="btn--green btn--full-width"
            />
        </div>
    );
};

export default withTranslationContext(BeforeProceedingMessage);
