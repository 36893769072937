/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';
import { ConnectedPreInspectionController } from './PreInspectionController';
import { PreInspectionGlassImages, PreInspectionCompleteImages, AdditionalImage } from '../../../reducers/types';

export interface PreInspectionContext {
    glass: PreInspectionGlassImages;
    complete: PreInspectionCompleteImages;
    setGlassPreInspectionPhotos(type: string, imageUrl: string | null): void;
    setCompletePreInspectionPhotos(type: string, imageUrl: string | null | AdditionalImage[]): void;
    getUserTokenFromPathToken(userId: number | string, token: string): Promise<string>;
}

export const preInspectionContextDefaultValue = {
    glass: {
        frontWindshield: null,
        frontRightWindshield: null,
        frontLeftWindshield: null,
        licensePlateWindshield: null,
    },
    complete: {
        frontBody: null,
        rightBody:  null,
        leftBody: null,
        backBody: null,
        ceiling: null,
        frontWindshield: null,
        frontRightWindshield: null,
        frontLeftWindshield: null,
        inside: null,
        identification: null,
        document: null,
        inspection: null,
        additionalPhotos: [],
    },
    setGlassPreInspectionPhotos: () => {},
    setCompletePreInspectionPhotos: () => {},
    getUserTokenFromPathToken: async () => ''
}

const PreInspectionContext = createContext<PreInspectionContext | null>(preInspectionContextDefaultValue);

export const PreInspectionContextProvider = PreInspectionContext.Provider;
export const PreInspectionContextConsumer = PreInspectionContext.Consumer;

export const withPreInspectionContext = <P extends object>(
    Component: ComponentType<P>,
): FC<Omit<P, keyof PreInspectionContext>> => props => (
    <ConnectedPreInspectionController>
        <PreInspectionContextConsumer>
            {ctx => <Component {...props as P} {...ctx} />}
        </PreInspectionContextConsumer>
    </ConnectedPreInspectionController>
);
