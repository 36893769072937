/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import logo from '../../../assets/images/logo_fluxe_vertical.svg';
import { RouteComponentProps } from 'react-router-dom';
import { MatchParams } from '../../../constants/misc';
import { ICON, SvgIcon } from '../../elements/SvgIcon';
import assistLogo from '../../../assets/images/fluxe_assist_logo.svg';

interface OwnProps extends RouteComponentProps<MatchParams>, TranslationContext {}

interface OwnState {
}

const initialState: OwnState = {
};

class DisputeScreen extends Component<OwnProps> {
    state = initialState;

    componentDidMount(): void {
    }

    render() {
        const { t } = this.props;

        return (
            <div className="step-screen">
                <div className="top-bar" />
                <div className="step-screen__content glass-welcome">
                    <img src={logo} alt="Fluxe Logo" />
                    <div className="title-container final-glass-screen">
                        <img src={assistLogo} alt="Fluxe Assist Logo" />
                        <h3>{t('welcome.glass')}</h3>
                        <p className="regular">{t('dispute.message')}</p>
                    </div>
                    <div className={`bottom glass-final success`}>
                        <SvgIcon icon={ICON.SUPPORT} />
                        <p>{t('expired.forMoreInformation')}</p>
                        <p>{t('expired.contactServiceInfo')}</p>
                        <h2>{t('expired.contactNumber')}</h2>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslationContext(DisputeScreen);
