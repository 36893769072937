/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { FilesState } from './types';
import { FilesAction } from '../actions/files_types';

export const filesInitialState: FilesState = {
    files: {
        frontBody: null,
        leftBody: null,
        rightBody: null,
        backBody: null,
        ceiling: null,
        document: null,
        frontLeftWindshield: null,
        frontRightWindshield: null,
        frontWindshield: null,
        identification: null,
        inside: null,
        inspection: null,
    },
    optionalFiles: [],
};

export default function (state = filesInitialState, action: any): FilesState {
    switch (action.type) {
        case FilesAction.SET_FILES:
            return {
                ...state,
                files: action.payload,
            };
        case FilesAction.SET_OPTIONAL_FILES:
            return {
                ...state,
                optionalFiles: action.payload,
            };
        default:
            return state;
    }
}
