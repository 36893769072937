/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

/**
 * Language
 * @enum {string}
 */
export enum Language {
    EN = 'en',
    PT = 'pt',
    ES = 'es',
}

/**
 * converts the language name to the corresponding value in the enum
 * @param {string} language
 * @returns {Language}
 */
export const languageNameToEnum = (language: string): Language => {
    if (language.toLowerCase() === 'en') return Language.EN;
    if (language.toLowerCase() === 'es') return Language.ES;
    return Language.PT;
};

/**
 * converts the language name to the corresponding value in the enum
 * @param {Language} language
 * @returns {string}
 */
export const languageEnumToName = (language: Language): string => {
    if (language === Language.EN) return 'en';
    if (language === Language.ES) return 'es';
    return 'pt';
};

/**
 * Theme
 * @enum {string}
 */

/**
 * @typedef {Object} Preferences
 * @property {Language} language
 * @property {Theme} theme
 */
export interface Preferences {
    language: Language;
}
