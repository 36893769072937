/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { CSSProperties } from 'react';
import { ReactSVG } from 'react-svg';

import iconSupport from '../../assets/images/icon-support.svg';
import iconStep1 from '../../assets/images/icon-step_1.svg';
import iconStep2 from '../../assets/images/icon-step_2.svg';
import iconPlus from '../../assets/images/icon-plus.svg';
import iconCross from '../../assets/images/icon-cross.svg';
import iconPlusCircle from '../../assets/images/icon-plus_circle.svg';
import iconRightChevron from '../../assets/images/icon-right_chevron.svg';
import iconLeftChevron from '../../assets/images/icon-left_chevron.svg';
import iconWrongCross from '../../assets/images/icon-wrong_cross.svg';
import iconStep1Success from '../../assets/images/icon-step_1_success.svg';
import iconStep2Success from '../../assets/images/icon-step_2_success.svg';
import iconStep1Error from '../../assets/images/icon-step_1_error.svg';
import iconStep2Error from '../../assets/images/icon-step_2_error.svg';
import iconVision from '../../assets/images/icon-vision.svg';
import iconLightBulb from '../../assets/images/icon-lightbulb.svg';
import iconGlassCleaner from '../../assets/images/icon-glass_cleaning.svg';
import iconGreenCar from '../../assets/images/icon-green_car.svg';
import iconRedCar from '../../assets/images/icon-red_car.svg';
import iconVideoCam from '../../assets/images/icon-video.svg';
import iconMicrophoneOff from '../../assets/images/icon-microphone_off.svg';
import iconMicrophoneOn from '../../assets/images/icon-microphone_on.svg';
import iconLicensePlateWindshield from '../../assets/images/icon-licensePlate_windshield.svg';
import backBody from '../../assets/images/backBody.svg';
import ceiling from '../../assets/images/ceiling.svg';
import document from '../../assets/images/document.svg';
import frontBody from '../../assets/images/frontBody.svg';
import frontLeftWindshield from '../../assets/images/frontLeftWindshield.svg';
import frontRightWindshield from '../../assets/images/frontRightWindshield.svg';
import frontWindshield from '../../assets/images/frontWindshield.svg';
import inside from '../../assets/images/inside.svg';
import leftBody from '../../assets/images/leftBody.svg';
import rightBody from '../../assets/images/rightBody.svg';
import vin from '../../assets/images/vin.svg';
import repairs from '../../assets/images/icon-repairs.svg';
import silkscreenFrontWindshield from '../../assets/images/icon_silkscreen-frontWindShield.svg';

interface OwnProps {
    icon: string;
    className?: string;
    callback?: Function;
}

export enum ICON {
    SUPPORT = 'SUPPORT',
    STEP_1 = 'STEP_1',
    STEP_2 = 'STEP_2',
    PLUS = 'PLUS',
    CROSS = 'CROSS',
    PLUS_CIRCLE = 'PLUS_CIRCLE',
    CHEVRON_RIGHT = 'CHEVRON_RIGHT',
    CHEVRON_LEFT = 'CHEVRON_LEFT',
    WRONG_CROSS = 'WRONG_CROSS',
    STEP_1_SUCCESS = 'STEP_1_SUCCESS',
    STEP_2_SUCCESS = 'STEP_2_SUCCESS',
    STEP_1_ERROR = 'STEP_1_ERROR',
    STEP_2_ERROR = 'STEP_2_ERROR',
    VISION = 'VISION',
    LIGHT_BULB = 'LIGHT_BULB',
    GLASS_CLEANER = 'GLASS_CLEANER',
    GREEN_CAR = 'GREEN_CAR',
    RED_CAR = 'RED_CAR',
    VIDEO_CAM = 'VIDEO_CAM',
    MICROPHONE_ON = 'MICROPHONE_ON',
    MICROPHONE_OFF = 'MICROPHONE_OFF',
    LICENSE_PLATE_WINDSHIELD = 'LICENSE_PLATE_WINDSHIELD',
    BACK_BODY = 'BACK_BODY',
    CEILING = 'CEILING',
    DOCUMENT = 'DOCUMENT',
    FRONT_BODY = 'FRONT_BODY',
    LEFT_BODY = 'LEFT_BODY',
    RIGHT_BODY = 'RIGHT_BODY',
    FRONT_LEFT_WINDSHIELD = 'FRONT_LEFT_WINDSHIELD',
    FRONT_RIGHT_WINDSHIELD = 'FRONT_RIGHT_WINDSHIELD',
    FRONT_WINDSHIELD = 'FRONT_WINDSHIELD',
    INSIDE = 'INSIDE',
    VIN = 'VIN',
    REPAIRS = 'REPAIRS',
    SERIGRAPHY = 'SERIGRAPHY',
}

export const SvgIcon = (props: OwnProps) => {
    const { className } = props;

    const getIcon = (): any => {
        switch (props.icon) {
            case ICON.SUPPORT:
                return iconSupport;
            case ICON.STEP_1:
                return iconStep1;
            case ICON.STEP_2:
                return iconStep2;
            case ICON.PLUS:
                return iconPlus;
            case ICON.CROSS:
                return iconCross;
            case ICON.PLUS_CIRCLE:
                return iconPlusCircle;
            case ICON.CHEVRON_RIGHT:
                return iconRightChevron;
            case ICON.WRONG_CROSS:
                return iconWrongCross;
            case ICON.CHEVRON_LEFT:
                return iconLeftChevron;
            case ICON.STEP_1_SUCCESS:
                return iconStep1Success;
            case ICON.STEP_2_SUCCESS:
                return iconStep2Success;
            case ICON.STEP_1_ERROR:
                return iconStep1Error;
            case ICON.STEP_2_ERROR:
                return iconStep2Error;
            case ICON.VISION:
                return iconVision;
            case ICON.LIGHT_BULB:
                return iconLightBulb;
            case ICON.GLASS_CLEANER:
                return iconGlassCleaner;
            case ICON.RED_CAR:
                return iconRedCar;
            case ICON.GREEN_CAR:
                return iconGreenCar;
            case ICON.MICROPHONE_ON:
                return iconMicrophoneOn;
            case ICON.MICROPHONE_OFF:
                return iconMicrophoneOff;
            case ICON.VIDEO_CAM:
                return iconVideoCam;
            case ICON.LICENSE_PLATE_WINDSHIELD:
                return iconLicensePlateWindshield;
            case ICON.BACK_BODY:
                return backBody;
            case ICON.CEILING:
                return ceiling;
            case ICON.DOCUMENT:
                return document;
            case ICON.FRONT_BODY:
                return frontBody;
            case ICON.FRONT_LEFT_WINDSHIELD:
                return frontLeftWindshield;
            case ICON.FRONT_RIGHT_WINDSHIELD:
                return frontRightWindshield;
            case ICON.FRONT_WINDSHIELD:
                return frontWindshield;
            case ICON.INSIDE:
                return inside;
            case ICON.LEFT_BODY:
                return leftBody;
            case ICON.RIGHT_BODY:
                return rightBody;
            case ICON.VIN:
                return vin;
            case ICON.REPAIRS:
                return repairs;
            case ICON.SERIGRAPHY:
                return silkscreenFrontWindshield;
            default: return '';
        }
    };

    const getStyles = (): CSSProperties => {
        let styles: CSSProperties = {
            pointerEvents: 'none',
        };

        if (props.callback) {
            styles = {
                cursor: 'pointer',
            };
        }

        return styles;
    };

    const onClick = () => {
        if (props.callback) {
            props.callback();
        }
    };

    return (
        <ReactSVG
            wrapper="span"
            onClick={onClick}
            className={`svg-icon ${className}`}
            src={getIcon()}
            style={getStyles()}
        />
    );
};
