/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import Loader from '../../elements/Loader';
import { AppState } from '../../../reducers/types';
import { STUN_SERVER_URL, TURN_SERVER_URL, WEB_SOCKET_URL } from '../../../settings';
import { RouteComponentProps } from 'react-router-dom';
import {
    MatchParams,
    SocketReceivedMessage,
    SocketReceivedMessageType,
    SocketSendMessageType,
} from '../../../constants/misc';
import { NOT_FOUND_ROUTE, VIDEO_CALL_ENDED_ROUTE, } from '../../../constants/routes';
import Button from '../../elements/Button';
import { ICON } from '../../elements/SvgIcon';
import moment from 'moment';
import { VideoData } from '../../../constants/general';

interface OwnProps extends RouteComponentProps<MatchParams>, TranslationContext {}

interface StateProps {
    videoData: VideoData;
}

type Props = OwnProps & StateProps;

interface OwnState {
    preparing: boolean;
    socket: WebSocket | null;
    calling: boolean;
    localStream: MediaStream;
    remoteStream: MediaStream;
    audioMuted: boolean;
    startTime: moment.Moment;
}

class VideoCallScreen extends Component<Props, OwnState> {
    peerConnection: RTCPeerConnection | null = null;

    constructor(props: Props) {
        super(props);

        this.state = {
            preparing: false,
            socket: null,
            calling: false,
            localStream: new MediaStream(),
            remoteStream: new MediaStream(),
            audioMuted: false,
            startTime: moment(),
        }
    }

    componentDidMount(): void {
        const { videoData, history } = this.props;
        const { token } = videoData;

        if (!token) {
            history.push(NOT_FOUND_ROUTE);
            return;
        }

        this.setState({
            localStream: new MediaStream(),
            remoteStream: new MediaStream(),
            audioMuted: false,
            calling: false,
            socket: null,
            preparing: true,
            startTime: moment(),
        }, () => { this.configSocket(); })
    }

    componentWillUnmount(): void {
        const { socket, calling } = this.state;

        if (socket) {
            let signal = {
                messageType: SocketSendMessageType.TERMINATE_OFFER,
            };

            if (calling) {
                signal = {
                    messageType: SocketSendMessageType.CANCEL_OFFER,
                };
            }

            // @ts-ignore
            socket.send(JSON.stringify(signal));

            // @ts-ignore
            if (this.peerConnection) {
                this.peerConnection.close();
            }

            socket.close();
        }
    }

    onMicrophoneClick = () => {
        const { localStream } = this.state;
        const tracks = localStream.getAudioTracks();

        for (let i = 0; i < tracks.length; i++) {
            tracks[i].enabled = !tracks[i].enabled;

            this.setState({
                audioMuted: !tracks[i].enabled,
            });
        }
    };

    onHangUpClick = () => {
        const { history } = this.props;
        const { socket, localStream } = this.state;

        if (!socket || !this.peerConnection) return;

        const signal = {
            messageType: SocketSendMessageType.TERMINATE_OFFER,
        };

        socket.send(JSON.stringify(signal));

        this.peerConnection.close();
        localStream.getTracks().forEach(track => {
            track.stop();
        });

        const durationStr = this.calculateCallTime();

        history.push({
            pathname: VIDEO_CALL_ENDED_ROUTE,
            state: {
                callDuration: durationStr,
            }
        });
    };

    configSocket = () => {
        if (!('WebSocket' in window)) {
            return;
        }

        this.setState({ preparing: true });

        const { videoData } = this.props;

        const socket = new WebSocket(`${String(WEB_SOCKET_URL)}/video/${videoData.token}`);

        socket.onmessage = this.handleSocketMessage;

        socket.onopen = () => {
            this.setState({
                socket,
                preparing: false,
            }, () => this.sendCallRequest());
        };
    };

    handleSocketMessage = (ev: MessageEvent) => {
        const { history } = this.props;
        const { data } = ev;
        const msg: SocketReceivedMessage = JSON.parse(data);
        const { messageType, credential, username } = msg;

        switch (messageType) {
            case SocketReceivedMessageType.TURN_CREDENTIALS:
                if (this.peerConnection && this.peerConnection?.connectionState !== "closed") {
                    this.peerConnection.setConfiguration({
                        iceServers: [
                            {
                                urls: [
                                    String(STUN_SERVER_URL)
                                ],
                            },
                            {
                                urls: [
                                    String(TURN_SERVER_URL)
                                ],
                                credential: credential,
                                username: username,
                            },
                        ],
                        iceCandidatePoolSize: 10,
                    });
                    return;
                }

                const that = this;
                
                this.peerConnection = new RTCPeerConnection({
                    iceServers: [
                        {
                            urls: [
                                String(STUN_SERVER_URL)
                            ],
                        },
                        {
                            urls: [
                                String(TURN_SERVER_URL)
                            ],
                            credential: credential,
                            username: username,
                        },
                    ],
                    iceCandidatePoolSize: 10,
                });

                this.peerConnection.addEventListener("iceconnectionstatechange", event => {
                    if (this.peerConnection && (this.peerConnection.iceConnectionState === "failed" ||
                    this.peerConnection?.iceConnectionState === "disconnected")) {
                      this.peerConnection.createOffer({iceRestart: true})
                        .then(offer => {
                            return that.peerConnection?.setLocalDescription(offer);
                        }).catch(() => {});
                    }
                });
                
                break;
            case SocketReceivedMessageType.INVALID_CALLEE:
            case SocketReceivedMessageType.INVALID_SDP:
            case SocketReceivedMessageType.INVALID_MESSAGE:
                history.push({
                    pathname: VIDEO_CALL_ENDED_ROUTE,
                    state: {
                        videoError: true,
                    }
                });
                break;
            case SocketReceivedMessageType.USER_BUSY:
            case SocketReceivedMessageType.USER_OFFLINE:
                this.closeCall();
                break;
            case SocketReceivedMessageType.INCOMING_CALL_CANCELED:
                this.closeCall();
                break;
            case SocketReceivedMessageType.CALL_ANSWERED:
                this.parseCallAnswer(msg);
                break;
            case SocketReceivedMessageType.CALL_DECLINED:
                this.closeCall();
                break;
            case SocketReceivedMessageType.ICE_CANDIDATE_FOUND:
                this.addIceCandidate(msg);
                break;
            case SocketReceivedMessageType.CALL_TERMINATED:
                this.onHangUpClick();
                break;
            case SocketReceivedMessageType.INVALID_TOKEN:
                history.push(NOT_FOUND_ROUTE);
                break;
            default:
        }
    };

    parseCallAnswer = async (message: SocketReceivedMessage) => {
        if (this.peerConnection && !this.peerConnection.currentRemoteDescription && message) {
            const answer = new RTCSessionDescription(message);
            await this.peerConnection.setRemoteDescription(answer);
        }

        this.setState({
            calling: false,
            startTime: moment(new Date()),
        });
    };

    addIceCandidate = async (message: SocketReceivedMessage) => {
        if (!message || !this.peerConnection) return;

        try {
            await this.peerConnection.addIceCandidate(new RTCIceCandidate(message.candidate));
        } catch (err) {
        }
    };

    sendCallRequest = async () => {
        const { videoData } = this.props;
        const { socket } = this.state;

        if (!socket) return;

        const credentialsSignal = {
            messageType: SocketSendMessageType.GET_TURN_CREDENTIALS,
        };

        socket.send(JSON.stringify(credentialsSignal));

        this.setState({ calling: true });

        const ableToOpenMedia: boolean = await this.openUserMedia();

        if (!ableToOpenMedia || !this.peerConnection) return;

        this.addVideoToCall();
        this.shareICECandidates();

        const offer = await this.peerConnection.createOffer({
            offerToReceiveAudio: true,
            offerToReceiveVideo: true,
        });

        await this.peerConnection.setLocalDescription(offer);
        const offerSignal = {
            messageType: SocketSendMessageType.CREATE_OFFER,
            to: 0,  // to admin
            type: offer.type,
            sdp: offer.sdp,
            preInspectionId: videoData.preInspectionId,
            preInspectionUid: videoData.preInspectionUid,
        };

        socket.send(JSON.stringify(offerSignal));

        this.listenToRemoteVideo();
    };

    openUserMedia = async () => {
        const { history } = this.props;
        const { remoteStream } = this.state;

        try {
            let stream = null;

            const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            if (isMobile) {
                stream = await navigator.mediaDevices.getUserMedia(
                    { video: {
                        facingMode: {
                            exact: 'environment'
                        }
                    }, audio: true });
            } else {
                stream = await navigator.mediaDevices.getUserMedia(
                    { video: true, audio: true });
            }

            const localVideoElement = document.querySelector<HTMLVideoElement>('#local-video');
            if (localVideoElement) {
                localVideoElement.srcObject = stream;
            }

            const remoteVideoElement = document.querySelector<HTMLVideoElement>('#remote-video');
            if (remoteVideoElement) {
                remoteVideoElement.srcObject = remoteStream;
            }

            this.setState({
                localStream: stream,
            });

            return true;
        } catch (e) {
            history.push({
                pathname: VIDEO_CALL_ENDED_ROUTE,
                state: {
                    videoError: true,
                }
            });
            return false;
        }
    };

    addVideoToCall = () => {
        const { localStream } = this.state;

        localStream.getTracks().forEach(track => {
            if (this.peerConnection) {
                this.peerConnection.addTrack(track, localStream);
            }
        })
    };

    shareICECandidates = () => {
        const { socket } = this.state;
        if (!this.peerConnection || !socket) return;

        this.peerConnection.addEventListener('icecandidate', event => {
            if (!event.candidate) {
                return;
            }

            const iceCandidate = {
                messageType: SocketSendMessageType.OFFER_ICE_CANDIDATE,
                candidate: event.candidate,
            };

            socket.send(JSON.stringify(iceCandidate));
        });
    };

    listenToRemoteVideo = () =>{
        if (!this.peerConnection) return;

        this.peerConnection.addEventListener('track', event => {
            const newRemoteStream = new MediaStream();
            event.streams[0].getTracks().forEach(track => {
                newRemoteStream.addTrack(track);
            });

            this.setState({
                remoteStream: newRemoteStream,
            }, () => this.putVideo());
        })
    };

    putVideo = () => {
        const { remoteStream } = this.state;
        const remoteVideoElement = document.querySelector<HTMLVideoElement>('#remote-video');

        if (remoteVideoElement) {
            remoteVideoElement.srcObject = remoteStream;
        }
    };

    closeCall = () => {
        const { history } = this.props;
        const { localStream } = this.state;

        if (this.peerConnection) this.peerConnection.close();

        localStream.getTracks().forEach(track => {
            track.stop();
        });

        history.push({
            pathname: VIDEO_CALL_ENDED_ROUTE,
            state: {
                callWasDeclined: true,
            }
        });
    };

    calculateCallTime = (): string => {
        const { startTime } = this.state;
        const { t } = this.props;
        const secondsStr = t('videoCall.seconds');

        const endTime = moment(new Date());
        const diff = moment.duration(endTime.diff(startTime));
        
        let durationStr: string = `${diff.seconds()} ${secondsStr}`;

        if (diff.minutes() > 0 && diff.hours() === 0) {
            durationStr = `${diff.minutes()} ${t('videoCall.min')} ${diff.seconds()} ${secondsStr}`
        } else if (diff.hours() > 0) {
            durationStr = `${diff.hours()} ${t('videoCall.hh')} ${diff.minutes()} ${t('videoCall.min')} ${diff.seconds()} ${secondsStr}`
        }

        return durationStr;
    };

    render() {
        const { t } = this.props;
        const { preparing, calling, audioMuted } = this.state;

        return (
            <div className="video-call">
                {preparing && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                <div className="video-call__video-container">
                    <video id="remote-video" className="video hidden" autoPlay playsInline />
                    <video id="local-video" className="video" muted autoPlay playsInline />
                    {calling && (
                        <div className="video-call__video-container__calling">
                            {t('videoCall.calling')}
                        </div>
                    )}
                    <div className="controls-container">
                        <Button
                            callback={this.onMicrophoneClick}
                            icon={audioMuted ? ICON.MICROPHONE_ON : ICON.MICROPHONE_OFF}
                            styles="btn--green"
                            iconPosition="left"
                        />
                        <Button
                            callback={this.onHangUpClick}
                            icon={ICON.VIDEO_CAM}
                            styles="btn--purple"
                            iconPosition="left"
                        />
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps({ general }: AppState): StateProps {
    const { videoData } = general;

    return {
        videoData,
    }
}

export default connect(mapStateToProps, {})(withTranslationContext(VideoCallScreen));
