/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { PreInspectionContextProvider } from './PreInspectionContext';
import {
    AdditionalImage,
    AppState,
    PreInspectionCompleteImages,
    PreInspectionGlassImages
} from '../../../reducers/types';
import { requestSetGlassPreInspectionPhotos, requestSetCompletePreInspectionPhotos } from '../../../actions/preInspections';
import { preInspectionVerifyToken } from '../../../services/pre_inspections';
import axios from 'axios';

interface StateProps {
    glass: PreInspectionGlassImages;
    complete: PreInspectionCompleteImages;
}

interface DispatchProps {
    makeRequestSetGlassPreInspectionPhotos: Function;
    makeRequestSetCompletePreInspectionPhotos: Function;
}

interface OwnProps extends StateProps {
    children: any;
}

type Props = StateProps & DispatchProps & OwnProps;

interface OwnState {}

type State = OwnState;

export class PreInspectionController extends Component<Props, State> {

    setGlassPreInspectionPhotos = (type: string, imageUrl: string | null) => {
        const {
            makeRequestSetGlassPreInspectionPhotos,
        } = this.props;
        makeRequestSetGlassPreInspectionPhotos(type, imageUrl);
    }

    setCompletePreInspectionPhotos = (type: string, imageUrl: string | null | AdditionalImage[]) => {
        const {
           makeRequestSetCompletePreInspectionPhotos,
        } = this.props;
        makeRequestSetCompletePreInspectionPhotos(type, imageUrl);
    }

    getUserTokenFromPathToken = async (userId: string | number, token: string): Promise<string> => {
        try {
           const { data } = await axios.post(preInspectionVerifyToken(userId), token, { headers: { 'content-type': 'text/plain' } });
           return data;
        } catch {
            return '';
        }
    }

    render() {
        const {
            glass,
            children,
            complete,
        } = this.props;

        return (
            <PreInspectionContextProvider value={{
                glass: glass,
                complete: complete,
                setGlassPreInspectionPhotos: this.setGlassPreInspectionPhotos,
                setCompletePreInspectionPhotos: this.setCompletePreInspectionPhotos,
                getUserTokenFromPathToken: this.getUserTokenFromPathToken,
            }}>
                {children}
            </PreInspectionContextProvider>
        )
    }
}

const mapStateToProps = (state: AppState): StateProps => {
    return {
        glass: state.preInspection.glass,
        complete: state.preInspection.complete,
    };
};

export const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => ({
    makeRequestSetGlassPreInspectionPhotos: (type: string, imageUrl: string | null) => { dispatch(requestSetGlassPreInspectionPhotos(type, imageUrl)); },
    makeRequestSetCompletePreInspectionPhotos: (type: string, imageUrl: string | null | AdditionalImage[]) => { dispatch(requestSetCompletePreInspectionPhotos(type, imageUrl)) }});

export const ConnectedPreInspectionController = connect(mapStateToProps, mapDispatchToProps)(PreInspectionController);
