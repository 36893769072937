import { OrientationType } from '../constants/general';
import { WEB_URL } from '../settings';
import { OriginPage } from '../constants/misc';

export const objectToParams = (obj: any, useQ = true) => {
    let params = '';

    if (obj) {
        Object.keys(obj).forEach(key => {
            if (obj[key]) {
                if (typeof obj[key] === 'string' && obj[key].length < 1) return;
                if (params.length > 0) {
                    params = `${params}&${key}=${obj[key]}`;
                } else {
                    params = `${key}=${obj[key]}`;
                }
            }
        });
    }

    if (useQ) {
        if (params && params.length > 0) {
            params = `?${params}`;
        }
    }

    return params;
};

export const parseStringType = (type: string): string => {
    const stringTypes: Record<string, string> = {
        frontLeftWindshield: 'FRONT_LEFT_WINDSHIELD',
        frontRightWindshield: 'FRONT_RIGHT_WINDSHIELD',
        frontWindshield: 'FRONT_WINDSHIELD',
        licensePlateWindshield: 'LICENSE_PLATE_WINDSHIELD',
        frontBody: 'FRONT_BODY',
        rightBody: 'RIGHT_BODY',
        leftBody: 'LEFT_BODY',
        backBody: 'BACK_BODY',
        ceiling: 'CEILING',
        inside: 'INSIDE',
        identification: 'IDENTIFICATION',
        inspection: 'INSPECTION',
        document: 'DOCUMENT',
        serigraphy: 'SERIGRAPHY',
        additionalPhotos: 'ADDITIONAL_PHOTOS',
    };

    const key = Object.keys(stringTypes).find(k => type.includes(k));
    return key ? stringTypes[key] : '';
};

export const evaluateOrientation = () => {
    if (window.innerHeight > window.innerWidth) {
        return OrientationType.PORTRAIT;
    }
    return OrientationType.LANDSCAPE;
};

export const addOrientationListener = (callback: Function) => {
    window.addEventListener('resize', () => {
        callback(evaluateOrientation());
    });
};

export const filterNullish = <T>(list: (T | null)[]): T[] => {
    const isTruthy = (item: T | null): item is T => !!item;
    return list.filter(isTruthy);
};

export const getUrlBackToWeb = (origin: string | null, brokenGlassControlId?: number): string => {
    let url = `${WEB_URL}/broken-glass-controls/all`;

    if (origin === OriginPage.EDIT_PAGE && brokenGlassControlId) {
        url = `${WEB_URL}/broken-glass-controls/edit/${brokenGlassControlId}`;
    }

    return url;
};
