/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';

export const brokenGlassControlURL = (id: number): string => {
    return `${API_URL}/glass-provider/broken-glass-control/${id}`;
};

export const brokenGlassControlCheckInURL = (id: number): string => {
    return `${API_URL}/glass-provider/broken-glass-control/${id}/check-in`;
};

export const brokenGlassControlCheckOutURL = (id: number): string => {
    return `${API_URL}/glass-provider/broken-glass-control/${id}/check-out`;
};

export const brokenGlassControlTypeURL = (id: number): string => {
    return `${API_URL}/glass-provider/broken-glass-control/${id}/control-type`;
};

export const brokenGlassControlDocumentsURL = (id: number): string => {
    return `${API_URL}/broken-glass-control/${id}/documents`;
};

export const brokenGlassControlDocumentURL = (id: number, fileId: number): string => {
    return `${API_URL}/broken-glass-control/${id}/documents/${fileId}`;
};

export const brokenGlassControlUploadCheckInURL = (id: number, fileType: string): string => {
    return `${API_URL}/glass-provider/broken-glass-control/${id}/upload/check-in/${fileType}`;
};

export const brokenGlassControlUploadCheckOutURL = (id: number, fileType: string): string => {
    return `${API_URL}/glass-provider/broken-glass-control/${id}/upload/check-out/${fileType}`;
};

export const brokenGlassControlDeleteCheckInPhotoURL = (id: number, fileType: string, fileHolderId: number): string => {
    return `${API_URL}/glass-provider/broken-glass-control/${id}/upload/check-in/${fileType}/${fileHolderId}`;
};

export const brokenGlassControlDeleteCheckOutPhotoURL = (id: number, fileType: string, fileHolderId: number): string => {
    return `${API_URL}/glass-provider/broken-glass-control/${id}/upload/check-out/${fileType}/${fileHolderId}`;
};
