/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

export enum BrokenGlassControlStatus {
    DONE = 'DONE',
    ONGOING = 'ONGOING',
    PENDING = 'PENDING',
}

export enum BrokenGlassControlPicturesType {
    WINDSHIELD = 'WINDSHIELD',
    REPAIR = 'REPAIR',
    OTHER = 'OTHER'
}

export interface BrokenGlassControl {
    id: number;
    status: BrokenGlassControlStatus;
    uid: string;
    insurancePolicyNumber: string | null;
    insured: string | null;
    insuranceCompanyId: number;
    insuranceCompanyName: string | null;
    controlDate: string;
    doneDate: string;
    vehicleLicensePlate: string | null;
    vehicleBrand: string | null;
    vehicleModel: string | null;
    glassProviderDistrict: string | null;
    glassProviderId: number;
    controlType: BrokenGlassControlPicturesType | null;
    checkInDate: string | null;
    checkedIn: boolean;
    checkOutDate: string | null;
    checkedOut: boolean;
}

export interface CheckInOutImages {
    id?: number;
    checkInFrontWindshieldPhotoId: number | null;
    checkInFrontRightWindshieldPhotoId: number | null;
    checkInFrontLeftWindshieldPhotoId: number | null;
    checkInSerigraphyPhotoId: number | null;
    checkInFrontBodyPhotoId: number | null;
    checkInAdditionalPhotosIds: Array<number>;
    checkOutFrontWindshieldPhotoId: number | null;
    checkOutFrontRightWindshieldPhotoId: number | null;
    checkOutFrontLeftWindshieldPhotoId: number | null;
    checkOutSerigraphyPhotoId: number | null;
    checkOutFrontBodyPhotoId: number | null;
    checkOutAdditionalPhotosIds: Array<number>;
}

export interface ApiFile {
    id: number;
    fileString: string;
    index?: number;
}

export enum ControlGlassImagesKeys {
    FrontBody = 'frontBody',
    FrontWindshield = 'frontWindshield',
    FrontRightWindshield = 'frontRightWindshield',
    FrontLeftWindshield = 'frontLeftWindshield',
    Serigraphy = 'serigraphy',
}

export interface ControlGlassImages {
    frontBody: ApiFile | null;
    frontWindshield: ApiFile | null;
    frontRightWindshield: ApiFile | null;
    frontLeftWindshield: ApiFile | null;
    serigraphy: ApiFile | null;
    additionalPhotos: Array<ApiFile>;
}

export type PhotosUploadingState<T> = {
    [K in keyof T]?: boolean;
};
