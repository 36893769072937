/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';

function NotFound() {
    return (
        <div className="app-screen" data-testid="notfound-screen-test">
            <div className="app-screen__container">
                <div className="app-screen__container__entry-page not-found">
                    404 - Not Found
                </div>
            </div>
        </div>
    )
}

export default NotFound;
