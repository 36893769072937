/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';

interface OwnProps {
    showModal: boolean;
    message: string;
}

const modalOutsideId = 'modal-outside';

const MessageBox: React.FC<OwnProps> = ({ showModal, message }: OwnProps) => {
    if (!showModal) return null;

    return (
        <div id={modalOutsideId} className="modal">
            <div className="modal__container">
                <div className="info-container">
                    <p>{message}</p>
                </div>
            </div>
        </div>
    );
};

export default MessageBox;
