/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { decode } from 'jsonwebtoken';
import { RouteComponentProps } from 'react-router-dom';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import Loader from '../../elements/Loader';
import logo from '../../../assets/images/logo_fluxe_vertical.svg';
import Button from '../../elements/Button';
import { MatchParams } from '../../../constants/misc';
import { setVideoDataActionCreator } from '../../../actions/general';
import { NOT_FOUND_ROUTE, VIDEO_ROUTE } from '../../../constants/routes';
import { VideoData } from '../../../constants/general';
import { withPreInspectionContext, PreInspectionContext } from '../../controllers/preInspection/PreInspectionContext';
import { GeneralContext, withGeneralContext } from '../../controllers/general/GeneralContext';

interface OwnProps extends RouteComponentProps<MatchParams>, TranslationContext, PreInspectionContext {}

interface DispatchProps {
    setVideoData: Function;
}

interface OwnState {
    preparing: boolean;
    userToken: string;
}

type Props = OwnProps & DispatchProps & GeneralContext;

const initialState: OwnState = {
    preparing: false,
    userToken: '',
};

class InformationVideoCall extends Component<Props, OwnState> {
    state = { ...initialState };

    async componentDidMount(): Promise<void> {
        const {
            match, history, getUserTokenFromPathToken, setUserToken,
        } = this.props;
        const { params } = match;
        const { token, userId } = params;
    
        if (token && userId) {
            setUserToken('');
            this.setState({
                userToken: await getUserTokenFromPathToken(userId, token),
            }, () => this.prepare());
        } else {
            history.push(NOT_FOUND_ROUTE);
        }
    }

    onAdvanceClick = () => {
        const { history } = this.props;
        history.push(VIDEO_ROUTE);
    };

    prepare = async () => {
        const { setVideoData } = this.props;
        const { userToken } = this.state;

        const data: any = decode(userToken);
        const { payload } = data;

        const videoData: VideoData = {
            token: userToken,
            preInspectionId: payload.id,
            preInspectionUid: payload.uuid,
        };

        setVideoData(videoData);
    };

    render() {
        const { t } = this.props;
        const { preparing } = this.state;

        return (
            <div className="video-call">
                {preparing && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                <div className="video-call__information-screen">
                    <img src={logo} alt="Fluxe Logo" />
                    <div>
                        <h1>{t('videoCall.information')}</h1>
                        <p>{t('videoCall.needsCameraAndMicrophone')}</p>
                        <p>{t('videoCall.iosInformation')}</p>
                    </div>
                    <Button
                        text={t('global.buttons.advance')}
                        styles="btn--purple btn--full-width"
                        callback={this.onAdvanceClick}
                    />
                </div>
            </div>
        );
    }
}

export default connect(null, {
    setVideoData: setVideoDataActionCreator,
})(withGeneralContext(withPreInspectionContext(withTranslationContext(InformationVideoCall))));
