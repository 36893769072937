/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import logoFluxe from '../../assets/images/logo_fluxe_horizontal.svg';
import rotatePhone from '../../assets/images/rotate_phone.svg';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';

interface OwnProps extends TranslationContext {}

class BlockOrientation extends Component<OwnProps> {
    render() {
        const { t } = this.props;
        return (
            <div className="app-screen">
                <div className="app-screen__container">
                    <div className="app-screen__container__block-orientation-page">
                        <img src={logoFluxe} alt="Fluxe Logo" />
                        <div className="centered">
                            <img src={rotatePhone} alt="" />
                            <div>
                                <span className="bold">{t('blockOrientation.bolderDescription')}</span>
                                <span>{t('blockOrientation.description')}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslationContext(BlockOrientation);