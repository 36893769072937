/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import axios from 'axios';
import { Store } from 'redux';
import jwt from 'jsonwebtoken';

/**
 * intercepts requests and adds the authorization header
 */
const setupRequestInterceptor = (store: Store): void => {
    axios.interceptors.request.use(
        reqConfig => {
            let newConfig = { ...reqConfig };

            const validToken = !!jwt.decode(store.getState().general.userToken);
            if (validToken && newConfig.url && newConfig.url.includes('broken-glass-control')) {
                newConfig = {
                    ...newConfig,
                    headers: {
                        ...newConfig.headers,
                        Authorization: `Bearer ${store.getState().general.userToken}`,
                    },
                };
                return newConfig;
            }

            return newConfig;
        },
        err => Promise.reject(err),
    );
};

/**
 * intercepts responses to update token and/or treat errors
 */
const setupResponseInterceptor = (): void => {
    axios.interceptors.response.use(response => {
        return response;
    }, error => {
        return Promise.reject(error);
    });
};

/**
 * sets up the necessary api configuration
 */
export const setupAPI = (store: Store): void => {
    axios.create({ withCredentials: true });
    setupRequestInterceptor(store);
    setupResponseInterceptor();
};
