/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { PreInspectionAction, PreInspectionActionType } from './preInspections_types';
import { AdditionalImage } from '../reducers/types';

export const requestSetGlassPreInspectionPhotos = (type: string, imageUrl: string | null): PreInspectionActionType => {
    return {
        type: PreInspectionAction.SET_GLASS_IMAGES,
        payload: {
            [type]: imageUrl,
        }
    }
}

export const requestSetCompletePreInspectionPhotos = (type: string, imageUrl: string | null | AdditionalImage[]) => {
    return {
        type: PreInspectionAction.SET_COMPLETE_IMAGES,
        payload: {
            [type]: imageUrl,
        }
    }
}
