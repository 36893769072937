/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import axios from 'axios';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { GeneralContext, withGeneralContext } from '../../controllers/general/GeneralContext';
import logo from '../../../assets/images/logo_fluxe_vertical.svg';
import { FINAL_GLASS_ERROR_ROUTE, DISPUTE_ROUTE } from '../../../constants/routes';
import { RouteComponentProps } from 'react-router-dom';
import { MatchParams } from '../../../constants/misc';
import { ICON, SvgIcon } from '../../elements/SvgIcon';
import assistLogo from '../../../assets/images/fluxe_assist_logo.svg';
import { disputesURL } from '../../../services/disputes';

interface OwnProps extends RouteComponentProps<MatchParams>, TranslationContext, GeneralContext {}

interface OwnState {
    isError: boolean;
    isFetching: boolean;
    showErrorMessage: boolean;
}

const initialState: OwnState = {
    isError: false,
    isFetching: false,
    showErrorMessage: false,
};

class GlassFinalScreen extends Component<OwnProps> {
    state = initialState;

    componentDidMount(): void {
        const { match } = this.props;
        const { path } = match;

        let isError: boolean = false;
        if (path === FINAL_GLASS_ERROR_ROUTE) {
            isError = true;
        }

        this.setState({ isError });
    }

    openDispute = () => {
        const { isFetching } = this.state;
        const { userToken, history } = this.props;

        if (isFetching) return;
        this.setState({
            isFetching: true,
            showErrorMessage: false,
        });
        axios.post(disputesURL(userToken)).then(() => {
            this.setState({
                isFetching: false,
            });
            history.push(DISPUTE_ROUTE);
        }).catch(() => {
            this.setState({
                isFetching: false,
                showErrorMessage: true,
            });
        });
    }

    render() {
        const { t } = this.props;
        const { isError, showErrorMessage } = this.state;

        const borderClass: string = isError ? 'damaged' : 'success';

        return (
            <div className="step-screen">
                <div className="top-bar" />
                <div className="step-screen__content glass-welcome">
                    <img src={logo} alt="Fluxe Logo" />
                    <div className="title-container final-glass-screen">
                        <img src={assistLogo} alt="Fluxe Assist Logo" />
                        <h3>{t('welcome.glass')}</h3>
                        <SvgIcon icon={isError ? ICON.RED_CAR : ICON.GREEN_CAR} />
                        <p className="bold">{isError ? t('glassFinal.damaged') : t('glassFinal.success')}</p>
                        <p className="regular">{t('glassFinal.help')}</p>
                    </div>
                    <div className={`bottom glass-final ${borderClass}`}>
                        <SvgIcon icon={ICON.SUPPORT} />
                        <p>{t('expired.forMoreInformation')}</p>
                        <p>{t('expired.contactServiceInfo')}</p>
                        <h2>{t('expired.contactNumber')}</h2>
                    </div>
                    <div className="bottom glass-open-dispute">
                        <span className="small">{t('glassFinal.disputeDescription')}</span>
                        <span className="ml-8" onClick={this.openDispute}>{t('glassFinal.disputeHere')}</span>
                    </div>
                    {showErrorMessage && (
                        <div className="bottom glass-open-dispute">
                            <div className="error">
                                <p>{t('glassFinal.disputeError')}</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default withGeneralContext(withTranslationContext(GlassFinalScreen));
