/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

export enum PreInspectionAction {
    SET_GLASS_IMAGES = 'SET_GLASS_IMAGES',
    SET_COMPLETE_IMAGES = 'SET_COMPLETE_IMAGES'
}

export interface SetGlassImages {
    type: typeof PreInspectionAction.SET_GLASS_IMAGES;
    payload: any;
}

export interface SetCompleteImages {
    type: typeof PreInspectionAction.SET_COMPLETE_IMAGES;
    payload: any;
}

export type PreInspectionActionType = SetGlassImages | SetCompleteImages;
